<script lang="ts" setup>
import { computed, onMounted, reactive, watch } from "vue";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { Actions } from "@/store";
import { Blog, blogValidations } from "@/models/blog";
import Loading from "@/components/common/Loading.vue";
import Field from "@/components/common/Field.vue";
import { useLoading } from "../common/loading.composable";
import FieldMarkdown from "../common/FieldMarkdown.vue";
import { useRoute, useRouter } from "vue-router";

const store = useStore();
const route = useRoute();
const router = useRouter();
const blog = reactive<Blog>({
  ...store.state.blog
});

watch(
  () => store.state.blog,
  (v) => {
    Object.assign(blog, {
      ...v
    });
  }
);

const isNew = computed<boolean>(() => blog.id === -1);
const { loading, setLoading } = useLoading();

const $v = useVuelidate(blogValidations, { blog });
const emit = defineEmits(["close"]);

onMounted(() => {
  if (!route.params.id) {
    store.dispatch(Actions.NewBlog);
    Object.assign(blog, { ...store.state.blog });
  }
});

const submit = async () => {
  $v.value.$touch();
  if (!$v.value.$invalid) {
    setLoading(true);
    await store.dispatch(Actions.StoreBlog, blog);

    setLoading(false);
    cancel();
  }
};

const cancel = () => {
  emit("close");
  router.push("/blog");
};
</script>
<template>
  <section class="drawer-content blog-form">
    <form @submit.prevent="submit" :class="{ submitting: loading }" v-if="blog">
      <h2 v-text="isNew ? 'Add blog' : 'Edit blog'"></h2>
      <p v-if="isNew">Fill out the details of the blog</p>
      <p v-else>Edit the details of the blog</p>

      <Field
        label="Title"
        :validation="$v.blog.title"
        v-model="blog.title"
      ></Field>
      <FieldMarkdown
        label="Text"
        :validation="$v.blog.text"
        v-model="blog.text"
        :upload-path="'/api/upload'"
      ></FieldMarkdown>

      <div class="buttons">
        <button
          class="confirm"
          type="button"
          :disabled="loading"
          @click="submit"
        >
          <span class="icon icon-pencil"></span>
          <span class="text" v-text="isNew ? 'Add' : 'Edit'"></span>
        </button>
        <button class="cancel" type="button" @click="cancel">
          <span class="text">Cancel</span>
        </button>
        <Loading v-if="loading"></Loading>
      </div>
    </form>
  </section>
</template>
@/models/blog
