<script lang="ts" setup>
import { PropType } from 'vue';
import { Result } from '@/models/results.model';
import { User } from '@/models/user';

const { result } = defineProps({
  result: {
    type: Object as PropType<Result<User>>,
    required: true,
  },
});
</script>
<template>
  <li class="search-result">
    <router-link :to="`/user/${result.item.id}`"
      >{{ result.item.first_name }} {{ result.item.last_name }}</router-link
    >
    <div>
      {{ result.item.email }}
    </div>
  </li>
</template>
