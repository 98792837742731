<script setup lang="ts">
import { convertMarkdown } from "@/markdown/util/markdown.util";
import { Note } from "@/models/note";
import { PropType } from "vue";

const { note } = defineProps({
  note: {
    type: Object as PropType<Note>,
    required: true
  }
});
</script>

<template>
  <li>
    <a :href.attr="`/note/${note.id}`">
      <h3>
        {{ note.title }}
      </h3>
    </a>
    <div v-html="convertMarkdown(note.description)"></div>
  </li>
</template>
