<script lang="ts" setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const auth = computed(() => store.state.auth);
</script>
<template>
  <nav>
    <ul class="menu" v-if="auth && auth.isUser">
      <li>
        <RouterLink to="/blog"> Blog </RouterLink>
      </li>
      <li>
        <RouterLink to="/application"> Applications </RouterLink>
      </li>
      <li>
        <RouterLink to="/learn"> Learn </RouterLink>
      </li>
      <li>
        <RouterLink to="/note"> Notes </RouterLink>
      </li>
      <li>
        <RouterLink to="/lesson"> Lessons </RouterLink>
      </li>
    </ul>
  </nav>
</template>
