<script setup lang="ts">
import { computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store";
import { Blog as BlogModel } from "@/models/blog";
import Blog from "./Blog.vue";

const store = useStore();
const router = useRouter();
const blogs = computed<BlogModel[]>(() => store.state.blogs);
const auth = computed(() => store.state.auth);

onMounted(() => {
  store.dispatch(Actions.GetBlogs);
});

const addBlog = () => {
  router.push("/blog/add");
};
</script>
<template>
  <div class="buttons" v-if="auth">
    <button type="button" class="option" @click="addBlog">
      <span class="icon icon-pencil"></span> Add blog
    </button>
  </div>
  <Blog v-for="blog in blogs" :blog="blog" listed> </Blog>
</template>
