<script setup lang="ts">
const props = defineProps({
  keys: {
    type: String,
    required: true
  }
});

const keys = props.keys.split(" + ");
</script>
<template>
  <template v-for="(key, index) in keys">
    <span class="key" v-text="key"></span>
    <span class="combination" v-if="index !== keys.length - 1"> + </span>
  </template>
  <span class="function"><slot /></span>
</template>
<style scoped>
.key {
  border: solid 1px gray;
  border-top-color: rgb(200, 200, 200);
  border-left-color: rgb(200, 200, 200);
  padding: 2px 4px;
  font-size: 9px;
  font-family: monospace;
  border-radius: 2px;
}
.combination {
  font-size: 9px;
}
.function {
  padding-left: 0.5em;
}
</style>
