<script lang="ts" setup>
import versionInfo from '@/version.json';
import { Version } from '@/models/version';

const version = new Version(versionInfo);
</script>
<template>
  <div class="version" v-text="version.version"></div>
</template>
<style scoped>
.version {
  position: fixed;
  bottom: 8px;
  right: 8px;
}
</style>
