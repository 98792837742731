import { onMounted, ref, watch } from "vue";
import {
  cleanHTML,
  isImage,
  insertNode,
  insertImage,
  insertQuote,
  insertCode,
  insertOrderedList,
  insertUnorderedList,
  insertInlineCode
} from "./util/html.util";
import { convertHTML, convertMarkdown } from "./util/markdown.util";
import { Attribute } from "./types";
import { getDocumentSelection } from "./util/selection.util";

export const MarkdownEditorComposable = (
  props: any,
  emit: any,
  editor: any,
  currentElement: any
) => {
  const fieldValue = ref<string>(props.modelValue);

  const showInsertDialog = ref<boolean>(false);
  const showAttributeDialog = ref<boolean>(false);
  const isInsert = ref<boolean>(false);

  const handleImage = () => {
    const docSelection = getDocumentSelection();

    const el = insertImage(docSelection);
    currentElement.value = el;
    showAttributeDialog.value = true;
    isInsert.value = true;
  };

  const handleQuote = () => {
    const docSelection = getDocumentSelection();

    insertQuote(docSelection);
    currentElement.value = null;
  };

  const handleCode = () => {
    const docSelection = getDocumentSelection();

    insertCode(docSelection);
    currentElement.value = null;
  };

  const handleInlineCode = () => {
    const docSelection = getDocumentSelection();

    insertInlineCode(docSelection);
    currentElement.value = null;
  };

  const handleOrderedList = () => {
    const docSelection = getDocumentSelection();

    insertOrderedList(docSelection);
    currentElement.value = null;
    update();
  };

  const handleUnorderedList = () => {
    const docSelection = getDocumentSelection();
    insertUnorderedList(docSelection);
    currentElement.value = null;
    update();
  };

  const onChange = (e: Event) => {
    cleanHTML(editor.value!);
    fieldValue.value = convertHTML(editor.value!);
    update();

    e.preventDefault();
  };

  watch(
    () => props.modelValue,
    (v) => {
      if (editor.value!.innerHTML === "") {
        editor.value!.innerHTML = convertMarkdown(v);
      }
    }
  );

  onMounted(() => {
    cleanHTML(editor.value!);

    if (props.modelValue) {
      editor.value!.innerHTML = convertMarkdown(props.modelValue);
    }
  });

  const updateSelection = (e: MouseEvent) => {
    if (e.target && isImage(e.target as Node)) {
      currentElement.value = e.target as HTMLElement;
      return;
    }
    const selection = getSelection();

    currentElement.value = selection?.anchorNode?.parentElement || undefined;
  };

  const onKeydown = (e: KeyboardEvent) => {
    // show insert menu and remember range
    if (e.metaKey && e.ctrlKey && e.key === "i") {
      e.preventDefault();
      showInsertDialog.value = !showInsertDialog.value;
    }
    if (e.metaKey && e.key === "h") {
      insertNode("h4");
      e.preventDefault();
    }
    if (e.metaKey && e.ctrlKey && e.key === "p") {
      insertNode("p");
      e.preventDefault();
    }
    if (e.metaKey && e.ctrlKey && e.key === "o") {
      insertNode("ol/li");
      e.preventDefault();
    }
    if (e.metaKey && e.ctrlKey && e.key === "u") {
      insertNode("ul/li");
      e.preventDefault();
    }
    if (e.metaKey && e.ctrlKey && e.key === "c") {
      const el = insertNode("pre", { type: "javascript" });
      currentElement.value = el;
      showAttributeDialog.value = true;
      isInsert.value = true;
      e.preventDefault();
    }
    if (e.metaKey && e.ctrlKey && e.key === "l") {
      const el = insertNode("a", { href: "", target: "_blank" });
      currentElement.value = el;
      showAttributeDialog.value = true;
      isInsert.value = true;
      e.preventDefault();
    }
    if (e.metaKey && e.ctrlKey && e.altKey && e.key === "c") {
      insertNode("code");
      e.preventDefault();
    }
    if (e.metaKey && e.ctrlKey && e.key === "m") {
      const el = insertNode(
        "img",
        { src: "", alt: "", align: "left", width: "" },
        true
      );
      currentElement.value = el;
      showAttributeDialog.value = true;
      isInsert.value = true;
    }
  };

  const confirmAttributes = (attributes: Attribute[]) => {
    if (currentElement.value) {
      for (var attr of attributes) {
        currentElement.value?.setAttribute(attr.name, attr.value);
      }
    }
    showAttributeDialog.value = false;
    isInsert.value = false;

    onChange(new Event("dummy"));
  };

  const cancelInsert = () => {
    if (isInsert.value) {
      currentElement.value?.parentElement?.removeChild(currentElement.value);
      currentElement.value = undefined;
    }
    showAttributeDialog.value = false;
    isInsert.value = false;
  };

  const removeElement = () => {
    if (currentElement.value && currentElement.value.parentNode) {
      currentElement.value.parentNode.removeChild(currentElement.value);
    }
    showAttributeDialog.value = true;
    currentElement.value = undefined;
    isInsert.value = false;
  };

  const editElementAttributes = () => {
    if (currentElement.value) {
      showAttributeDialog.value = true;
      isInsert.value = false;
    }
  };

  const handlePaste = (e: ClipboardEvent) => {
    e.preventDefault();
    const paste = e.clipboardData?.getData("text");
    const selection = window.getSelection();
    if (!selection || !selection.rangeCount || !paste) return;
    selection.deleteFromDocument();
    selection.getRangeAt(0).insertNode(document.createTextNode(paste));
    selection.collapseToEnd();
  };

  const update = () => {
    fieldValue.value = convertHTML(editor.value!);

    emit("update:modelValue", fieldValue.value);
    emit("input", fieldValue.value);
  };

  return {
    showInsertDialog,
    showAttributeDialog,
    currentElement,
    onChange,
    onKeydown,
    handlePaste,
    update,
    confirmAttributes,
    cancelInsert,
    removeElement,
    editElementAttributes,
    handleImage,
    handleQuote,
    handleCode,
    handleInlineCode,
    handleOrderedList,
    handleUnorderedList,
    updateSelection,
    ...props
  };
};
