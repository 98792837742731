import { required, requiredIf } from "@vuelidate/validators";

export class Shortcut {
  public id: number;
  public name: string;
  public type: string;
  public description: string;
  public application_id: number;
  public mode: string;
  public shortcut_key: string;
  public ctrl_key: boolean;
  public shift_key: boolean;
  public alt_key: boolean;
  public command_key: boolean;
  public left_mouse_button: boolean;
  public right_mouse_button: boolean;
  public modifiers: number[];

  constructor(data: Shortcut) {
    this.id = data.id;
    this.name = data.name;
    this.type = data.type;
    this.description = data.description;
    this.application_id = data.application_id;
    this.mode = data.mode;
    this.shortcut_key = data.shortcut_key;
    this.ctrl_key = data.ctrl_key;
    this.shift_key = data.shift_key;
    this.alt_key = data.alt_key;
    this.command_key = data.command_key;
    this.left_mouse_button = data.left_mouse_button;
    this.right_mouse_button = data.right_mouse_button;
    this.modifiers = data.modifiers;
  }
}

export const shortcutValidations = {
  shortcut: {
    name: { required },
    description: { required },
    shortcut_key: {
      required: requiredIf(function () {
        return (
          !arguments[1].left_mouse_button && !arguments[1].right_mouse_button
        );
      })
    }
  }
};
