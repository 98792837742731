import {
  BaseService,
  Status,
  DataResponse,
  MessageData,
  MessageResponse
} from "@/core/services/base.service";
import { Lesson } from "@/models/lesson";

class LessonService extends BaseService {
  private url: string = "/api/lesson";
  private cached: Lesson[] = [];

  constructor() {
    super();
  }

  public new(): Lesson {
    return new Lesson({
      id: -1,
      application_id: -1,
      date_created: "",
      name: "",
      description: "",
      author: "",
      year: new Date().getFullYear(),
      url: ""
    });
  }

  public async getList(): Promise<DataResponse<Lesson[]>> {
    if (this.hasCache()) {
      return {
        status: true,
        data: this.getCache()
      };
    }
    const res = await this.$http.get<Lesson[]>(`${this.url}`);
    this.setCache(res.data.map((d) => new Lesson(d)));
    return {
      status: res.status === Status.Ok,
      data: this.getCache()
    };
  }

  public async get(id: number): Promise<DataResponse<Lesson>> {
    const res = await this.$http.get<Lesson>(`${this.url}/${id}`);
    return {
      status: res.status === Status.Ok,
      data: new Lesson(res.data)
    };
  }

  public async post(lesson: Lesson): Promise<DataResponse<Lesson>> {
    const res = await this.$http.post<Lesson>(this.url, {
      ...lesson,
      date_created: new Date().toISOString()
    });
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      data: new Lesson(res.data)
    };
  }

  public async put(lesson: Lesson): Promise<DataResponse<Lesson>> {
    const res = await this.$http.put<Lesson>(
      `${this.url}/${lesson.id}`,
      lesson
    );
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      data: new Lesson(res.data)
    };
  }

  public async delete(id: number): Promise<MessageResponse> {
    const res = await this.$http.delete<MessageData>(`${this.url}/${id}`);
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      message: res.data.message
    };
  }

  private getCache(): Lesson[] {
    return this.cached;
  }

  private setCache(list: Lesson[]): void {
    this.cached = list;
  }

  private hasCache(): boolean {
    return this.cached.length !== 0;
  }

  private clearCache(): void {
    this.setCache([]);
  }
}

export default new LessonService();
