<script setup lang="ts">
import { PropType, ref } from "vue";
import { MarkdownEditorComposable } from "./markdown-editor";
import InsertDialog from "./components/InsertDialog.vue";
import AttributeDialog from "./components/AttributeDialog.vue";
import Toolbar from "./components/Toolbar.vue";

const props = defineProps({
  modelValue: {
    type: String as PropType<string>,
    default: ""
  },
  uploadPath: {
    type: String,
    default: ""
  }
});

const emit = defineEmits(["update:modelValue", "input"]);

const editor = ref<HTMLElement>();
const currentElement = ref<HTMLElement>();

const {
  showInsertDialog,
  showAttributeDialog,
  onChange,
  onKeydown,
  confirmAttributes,
  cancelInsert,
  removeElement,
  editElementAttributes,
  handlePaste,
  handleImage,
  handleQuote,
  handleCode,
  handleInlineCode,
  handleOrderedList,
  handleUnorderedList,
  updateSelection,
  uploadPath
} = MarkdownEditorComposable(props, emit, editor, currentElement);
</script>
<template>
  <div class="editor-wrapper">
    <label class="editor-label" @click="showInsertDialog = !showInsertDialog"
      >Markdown</label
    >
    <div
      ref="editor"
      class="editor"
      contenteditable="true"
      @paste="handlePaste"
      @click="updateSelection"
      @blur="onChange"
      @keydown="onKeydown"
    >
      <p></p>
    </div>
    <Toolbar
      :currentElement="currentElement"
      @edit="editElementAttributes"
      @remove="removeElement"
      @insert-image="handleImage"
      @insert-quote="handleQuote"
      @insert-code="handleCode"
      @insert-inline-code="handleInlineCode"
      @insert-ordered-list="handleOrderedList"
      @insert-unordered-list="handleUnorderedList"
    ></Toolbar>
    <InsertDialog :show="showInsertDialog" @close="showInsertDialog = false" />
    <AttributeDialog
      v-if="currentElement && showAttributeDialog"
      :currentElement="currentElement"
      :show="showAttributeDialog"
      :uploadPath="uploadPath"
      @confirm="confirmAttributes"
      @cancel="cancelInsert"
    />
  </div>
</template>
<style scoped src="./css/markdown-editor.less" lang="less"></style>
