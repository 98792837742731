import { required } from "@vuelidate/validators";

export class Blog {
  public id: number;
  public date: string;
  public title: string;
  public text: string;
  public published: boolean;

  constructor(data: Blog) {
    this.id = data.id;
    this.date = data.date;
    this.title = data.title;
    this.text = data.text;
    this.published = data.published;
  }
}

export const blogValidations = {
  blog: {
    date: {},
    title: { required },
    text: { required }
  }
};
