<script setup lang="ts">
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Actions, Mutations } from "@/store";
import { Application } from "@/models/application";
import { Note } from "@/models/note";
import { Shortcut } from "@/models/shortcut";
import ShortcutKey from "../shortcut/ShortcutKey.vue";

const store = useStore();
const route = useRoute();
const router = useRouter();
const application = computed<Application>(() => store.state.application);
const notes = computed<Note[]>(() => store.state.notes);
const shortcuts = computed(() => store.state.shortcuts);

onMounted(() => {
  store.dispatch(Actions.GetApplication, route.params.id);
  store.dispatch(Actions.GetNotes);
  store.dispatch(Actions.GetShortcuts);
});

const getNotes = (ids: number[] = []) =>
  notes.value.filter((n: Note) => ids.includes(n.id));
const getShortcuts = (app: Application): Shortcut[] =>
  shortcuts.value
    .filter((s: Shortcut) => s.application_id === app.id)
    .sort((a: Shortcut, b: Shortcut) => (a.name > b.name ? 1 : -1));

const edit = () => {
  store.commit(Mutations.SetApplication, application.value);
  router.push(`/application/${application.value.id}/edit`);
};
</script>
<template>
  <div class="buttons">
    <button type="button" class="option" @click="edit">
      <span class="icon icon-pencil"></span> Edit
    </button>
  </div>
  <section class="application" v-if="application">
    <h2 v-text="application.name"></h2>
    <p v-text="application.description"></p>
    <div class="columns">
      <ul class="col half">
        <li v-for="note in getNotes(application.features)">
          <a :href.attr="`/note/${note.id}`">{{ note.title }}</a>
          <ul v-if="getNotes(note.chapters).length">
            <li v-for="n in getNotes(note.chapters)">
              <a :href.attr="`/note/${n.id}`">{{ n.title }}</a>
            </li>
          </ul>
        </li>
      </ul>
      <ul class="col half">
        <li v-for="shortcut in getShortcuts(application)">
          <ShortcutKey :shortcut="shortcut"></ShortcutKey>
        </li>
      </ul>
    </div>
  </section>
</template>
