import {
  BaseService,
  Status,
  DataResponse,
  MessageData,
  MessageResponse
} from "@/core/services/base.service";
import { Application } from "@/models/application";

class ApplicationService extends BaseService {
  private url: string = "/api/application";
  private cached: Application[] = [];

  constructor() {
    super();
  }

  public new(): Application {
    return new Application({
      id: -1,
      name: "",
      description: "",
      website: "",
      modes: [],
      features: []
    });
  }

  public async getList(): Promise<DataResponse<Application[]>> {
    if (this.hasCache()) {
      return {
        status: true,
        data: this.getCache()
      };
    }
    const res = await this.$http.get<Application[]>(`${this.url}`);
    this.setCache(res.data.map((d) => new Application(d)));
    return {
      status: res.status === Status.Ok,
      data: this.getCache()
    };
  }

  public async get(id: number): Promise<DataResponse<Application>> {
    const res = await this.$http.get<Application>(`${this.url}/${id}`);
    return {
      status: res.status === Status.Ok,
      data: new Application(res.data)
    };
  }

  public async post(
    application: Application
  ): Promise<DataResponse<Application>> {
    const res = await this.$http.post<Application>(this.url, application);
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      data: new Application(res.data)
    };
  }

  public async put(
    application: Application
  ): Promise<DataResponse<Application>> {
    const res = await this.$http.put<Application>(
      `${this.url}/${application.id}`,
      application
    );
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      data: new Application(res.data)
    };
  }

  public async delete(id: number): Promise<MessageResponse> {
    const res = await this.$http.delete<MessageData>(`${this.url}/${id}`);
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      message: res.data.message
    };
  }

  private getCache(): Application[] {
    return this.cached;
  }

  private setCache(list: Application[]): void {
    this.cached = list;
  }

  private hasCache(): boolean {
    return this.cached.length !== 0;
  }

  private clearCache(): void {
    this.setCache([]);
  }
}

export default new ApplicationService();
