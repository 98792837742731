import { required } from "@vuelidate/validators";

export class Lesson {
  public id: number;
  public application_id: number;
  public date_created: string;
  public name: string;
  public description: string;
  public author: string;
  public year: number;
  public url: string;

  constructor(data: Lesson) {
    this.id = data.id;
    this.application_id = data.application_id;
    this.date_created = data.date_created;
    this.name = data.name;
    this.description = data.description;
    this.author = data.author;
    this.year = data.year;
    this.url = data.url;
  }
}

export const lessonValidations = {
  lesson: {
    name: { required },
    url: { required }
  }
};
