<script setup lang="ts">
import { PropType, computed } from "vue";
import { getElementName } from "../config";

const props = defineProps({
  currentElement: {
    type: Object as PropType<HTMLElement | undefined>
  }
});

const emit = defineEmits([
  "edit",
  "remove",
  "insertImage",
  "insertQuote",
  "insertCode",
  "insertInlineCode",
  "insertUnorderedList",
  "insertOrderedList"
]);

const element = computed(() => {
  const tagName = props.currentElement?.tagName.toLowerCase() || "";

  const name = getElementName(tagName);
  if (name) {
    return {
      name,
      tagName
    };
  }
  return {
    name: "",
    tagName: ""
  };
});
const isBlockElement = computed(() =>
  ["p", "li"].includes(element.value.tagName)
);
const isOrderedListElement = computed(
  () => props.currentElement?.parentElement?.tagName.toLowerCase() === "ol"
);
const isUnorderedListElement = computed(
  () => props.currentElement?.parentElement?.tagName.toLowerCase() === "ul"
);
const hasProperties = computed(() =>
  ["img", "a", "pre"].includes(element.value.tagName)
);

const remove = () => {
  emit("remove");
};

const edit = () => {
  emit("edit");
};

const insertImage = () => {
  emit("insertImage");
};
const insertQuote = () => {
  emit("insertQuote");
};
const insertCode = () => {
  emit("insertCode");
};
const insertInlineCode = () => {
  emit("insertInlineCode");
};
const insertOrderedList = () => {
  emit("insertOrderedList");
};
const insertUnorderedList = () => {
  emit("insertUnorderedList");
};
</script>
<template>
  <div class="editor-toolbar">
    <!-- <button type="button" disabled class="icon-bold"></button>
    <button type="button" disabled class="icon-italic"></button>
    <button type="button" disabled class="icon-underline"></button>
    <button type="button" disabled class="icon-strikethrough"></button>
    <button type="button" disabled class="icon-link"></button>
    <button type="button" disabled class="icon-indent-increase"></button>
    <button type="button" disabled class="icon-indent-decrease"></button> -->
    <button
      type="button"
      :disabled="!!element && !isBlockElement"
      @click="insertQuote"
      class="icon-quotes-left"
    ></button>
    <button
      type="button"
      :disabled="!!element && !isBlockElement"
      @click="insertImage"
      class="icon-image"
    ></button>
    <button
      type="button"
      :disabled="!!element && !isBlockElement"
      @click="insertCode"
      class="icon-embed2"
    ></button>
    <button
      type="button"
      :disabled="!!element && !isBlockElement"
      @click="insertInlineCode"
      class="icon-embed2"
    ></button>
    <button
      type="button"
      :disabled="!!element && !isBlockElement"
      @click="insertOrderedList"
      :class="{ 'icon-list-numbered': true, active: isOrderedListElement }"
    ></button>
    <button
      type="button"
      :disabled="!!element && !isBlockElement"
      @click="insertUnorderedList"
      :class="{ 'icon-list': true, active: isUnorderedListElement }"
    ></button>
    <button
      type="button"
      :disabled="!!element && !hasProperties"
      @click.prevent.stop="edit"
      class="icon-cog"
    ></button>
    <button
      type="button"
      v-disabled="!!element"
      @click.prevent.stop="remove"
      class="icon-cross"
    ></button>
    <div class="focus-element">{{ element?.name }}</div>
  </div>
</template>
<style scoped src="../css/markdown-editor.less" lang="less"></style>
