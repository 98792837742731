import { required } from "@vuelidate/validators";

export class Application {
  public id: number;
  public name: string;
  public description: string;
  public website: string;
  public modes: string[];
  public features: number[];

  constructor(data: Application) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.website = data.website;
    this.modes = data.modes;
    this.features = data.features;
  }
}

export const applicationValidations = {
  application: {
    date: {},
    name: { required },
    website: { required }
  }
};
