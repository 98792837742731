import {
  BaseService,
  Status,
  DataResponse,
  MessageData,
  MessageResponse
} from "@/core/services/base.service";
import { Blog } from "@/models/blog";
import { format } from "date-fns";

class BlogService extends BaseService {
  private url: string = "/api/blog";
  private cached: Blog[] = [];

  constructor() {
    super();
  }

  public new(): Blog {
    return new Blog({
      id: -1,
      date: format(new Date(), "yyyy-MM-dd"),
      title: "",
      text: "\n",
      published: false
    });
  }

  public async getList(): Promise<DataResponse<Blog[]>> {
    if (this.hasCache()) {
      return {
        status: true,
        data: this.getCache()
      };
    }
    const res = await this.$http.get<Blog[]>(`${this.url}`);
    this.setCache(
      res.data
        .map((d) => new Blog(d))
        .sort((a, b) => Date.parse(b.date) - Date.parse(a.date))
    );
    return {
      status: res.status === Status.Ok,
      data: this.getCache()
    };
  }

  public async get(id: number): Promise<DataResponse<Blog>> {
    const res = await this.$http.get<Blog>(`${this.url}/${id}`);
    return {
      status: res.status === Status.Ok,
      data: new Blog(res.data)
    };
  }

  public async post(blog: Blog): Promise<DataResponse<Blog>> {
    const res = await this.$http.post<Blog>(this.url, {
      ...blog,
      date: new Date().toISOString()
    });
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      data: new Blog(res.data)
    };
  }

  public async put(blog: Blog): Promise<DataResponse<Blog>> {
    const res = await this.$http.put<Blog>(`${this.url}/${blog.id}`, blog);
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      data: new Blog(res.data)
    };
  }

  public async delete(id: number): Promise<MessageResponse> {
    const res = await this.$http.delete<MessageData>(`${this.url}/${id}`);
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      message: res.data.message
    };
  }

  private getCache(): Blog[] {
    return this.cached;
  }

  private setCache(list: Blog[]): void {
    this.cached = list;
  }

  private hasCache(): boolean {
    return this.cached.length !== 0;
  }

  private clearCache(): void {
    this.setCache([]);
  }
}

export default new BlogService();
