<script lang="ts" setup>
import { computed, onMounted, markRaw } from 'vue';
import { useStore } from 'vuex';
import { Level, User } from '@/models/user';
import CompoundList, { CompoundListItem } from '../common/CompoundList.vue';
import Tooltip from '@/components/common/Tooltip.vue';
import ProfileForm from '@/components/user/ProfileForm.vue';
import { Actions } from '@/store';
import { DrawerMutations } from '@/stores/drawer.store';

const store = useStore();
const profile = computed<User>(() => store.state.profile);

onMounted(() => {
  store.dispatch(Actions.GetProfile);
});

const showProfileForm = () => {
  store.commit(DrawerMutations.OpenDrawer, {
    drawer: markRaw(ProfileForm),
    profile: profile.value,
  });
};

const props = (): CompoundListItem[] => {
  return [
    {
      name: 'Name',
      description: `${profile.value.first_name} ${profile.value.last_name}`,
    },
    { name: 'Email', description: profile.value.email },
    { name: 'Phone', description: profile.value.phone },
    {
      name: 'Level',
      description: profile.value.level === Level.Admin ? 'Admin' : 'User',
    },
  ];
};
</script>
<template>
  <section class="user" v-if="profile">
    <h2>
      Profile
      <Tooltip text="Edit profile" inline>
        <button
          @click.prevent="showProfileForm"
          type="button"
          class="option icononly"
        >
          <span class="icon icon-pencil"></span>
        </button>
      </Tooltip>
    </h2>
    <CompoundList :list="props()" simple />
  </section>
</template>
