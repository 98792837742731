<script lang="ts" setup>
import { onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Actions } from "@/store";
import Note from "@/components/note/Note.vue";

const store = useStore();
const route = useRoute();

onMounted(() => {
  store.dispatch(Actions.GetApplications);
  store.dispatch(Actions.GetNote, route.params.id);
  store.dispatch(Actions.GetShortcuts);
  store.dispatch(Actions.GetNotes);
});
</script>
<template><Note></Note></template>
