<script setup lang="ts">
import Key from "./Key.vue";

const props = defineProps({
  show: {
    type: Boolean,
    required: true
  }
});

const emit = defineEmits(["close"]);
</script>
<template>
  <div class="editor-insert-dialog" v-if="props.show">
    <h4>Insert options</h4>
    <ul>
      <li><Key keys="⌘ + H">Header</Key></li>
      <li><Key keys="⌘ + P">Paragraph</Key></li>
      <li><Key keys="⌘ + Ctrl + N">Ordered List</Key></li>
      <li><Key keys="⌘ + Ctrl + U">Unordered List</Key></li>
      <li><Key keys="⌘ + Ctrl + C">Code</Key></li>
      <li><Key keys="⌘ + M">Image</Key></li>
      <li><Key keys="⌘ + B">Bold</Key></li>
      <li><Key keys="⌘ + I">Italic</Key></li>
      <li><Key keys="⌘ + S">Strike</Key></li>
      <li><Key keys="⌘ + Ctrl + L">Link</Key></li>
    </ul>
    <button type="button" @click="emit('close')">close</button>
  </div>
</template>
