<script setup lang="ts">
import { computed, markRaw, onMounted } from "vue";
import { useStore } from "vuex";
import { Note } from "@/models/note";
import { Actions } from "@/store";
import { useRouter } from "vue-router";
import NoteCard from "./NoteCard.vue";
import Filter from "@/components/common/Filter.vue";
import { DrawerMutations } from "@/stores/drawer.store";
import ShortcutForm from "../shortcut/ShortcutForm.vue";

const store = useStore();
const router = useRouter();
const notes = computed<Note[]>(() =>
  [...store.getters.filteredNotes].sort((a, b) => (a.title > b.title ? 1 : -1))
);

onMounted(() => {
  store.dispatch(Actions.GetNotes);
});

const addNote = () => {
  store.dispatch(Actions.NewNote);
  router.push("/note/add");
};

const addShortcut = () => {
  store.dispatch(Actions.NewShortcut);
  store.commit(DrawerMutations.OpenDrawer, {
    drawer: markRaw(ShortcutForm),
    data: {
      drawer: true
    }
  });
};
</script>
<template>
  <div class="buttons">
    <Filter />
    <button type="button" class="option" @click="addShortcut">
      <span class="icon icon-pencil"></span> Add Shortcut
    </button>
    <button type="button" class="option" @click="addNote">
      <span class="icon icon-pencil"></span> Add Note
    </button>
  </div>
  <section class="note">
    <h2>Notes</h2>

    <ul class="col half note-list">
      <NoteCard v-for="note in notes" :note="note"></NoteCard>
    </ul>
  </section>
</template>
