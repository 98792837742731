<script lang="ts" setup>
import { computed, markRaw, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { Actions } from "@/store";
import { Note, noteValidations } from "@/models/note";
import Loading from "@/components/common/Loading.vue";
import Field from "@/components/common/Field.vue";
import { useLoading } from "../common/loading.composable";
import { DrawerMutations } from "@/stores/drawer.store";
import ShortcutForm from "../shortcut/ShortcutForm.vue";
import { Shortcut } from "@/models/shortcut";
import { useEvents, Event } from "@/core/services/events.composable";
import FieldMarkdown from "../common/FieldMarkdown.vue";
import FieldItems from "../common/FieldItems.vue";
import { useRouter } from "vue-router";
import AddNoteForm from "./AddNoteForm.vue";

const store = useStore();
const router = useRouter();
const events = useEvents();

const note = reactive<Note>({
  ...store.state.note
});
const notes = computed<Note[]>(() => store.state.notes);
const shortcut = computed<Shortcut>(() => store.state.shortcut);
const shortcuts = computed<Shortcut[]>(() => store.state.shortcuts);

const isNew = computed<boolean>(() => note?.id === -1);

const { loading, setLoading } = useLoading();

onMounted(() => {
  store.dispatch(Actions.GetApplications);
  store.dispatch(Actions.GetNotes);
  store.dispatch(Actions.GetShortcuts);

  if (!store.state.note || store.state.note.id === -1) {
    store.dispatch(Actions.NewNote);
    Object.assign(note, { ...store.state.note });
  }
});

events.on(Event.ShortcutCreated, () => {
  note.shortcut_keys = [...(note.shortcut_keys || []), shortcut.value.id];
});

const $v = useVuelidate(noteValidations, { note });
const emit = defineEmits(["close"]);

const submit = async () => {
  $v.value.$touch();
  if (!$v.value.$invalid) {
    setLoading(true);
    await store.dispatch(Actions.StoreNote, note);

    setLoading(false);
    cancel();
  }
};

const cancel = () => {
  emit("close");
  if (note.id === -1) {
    router.push(`/note`);
  } else {
    router.push(`/note/${note.id}`);
  }
};

const addUrl = () => {
  note.urls = [...(note.urls || []), { label: "", url: "" }];
};

const removeUrl = (index: number) => {
  note.urls.splice(index, 1);
};

const addShortcut = () => {
  store.commit(DrawerMutations.OpenDrawer, {
    drawer: markRaw(ShortcutForm),
    data: {
      application: note.application_id,
      shortcuts: shortcuts.value.filter(
        (s) => !(note.shortcut_keys || []).includes(s.id)
      )
    }
  });
};

const removeShortcut = (index: number) => {
  note.shortcut_keys.splice(index, 1);
};

const getShortCut = (id: number): string => {
  return shortcuts.value.find((s) => s.id === id)?.name || "unknown";
};

const addNote = () => {
  store.commit(DrawerMutations.OpenDrawer, {
    drawer: markRaw(AddNoteForm),
    data: {
      notes: notes.value.filter((s) => !(note.chapters || []).includes(s.id))
    }
  });
};

events.on(Event.NoteCreated, (n) => {
  note.chapters = [...(note.chapters || []), n.id];
});

const removeNote = (index: number) => {
  note.chapters.splice(index, 1);
};

const getNote = (id: number): string => {
  return notes.value.find((s) => s.id === id)?.title || "unknown";
};
</script>
<template>
  <section class="drawer-content note-form">
    <form @submit.prevent="submit" :class="{ submitting: loading }" v-if="note">
      <h2 v-text="isNew ? 'Add note' : 'Edit note'"></h2>
      <p v-if="isNew">Fill out the details of the note</p>
      <p v-else>Edit the details of the note</p>

      <Field
        label="Title"
        :validation="$v.note.title"
        v-model="note.title"
      ></Field>
      <FieldMarkdown
        label="Description"
        :validation="$v.note.description"
        v-model="note.description"
        :upload-path="'/api/upload'"
      ></FieldMarkdown>
      <FieldItems label="Urls" @addItem="addUrl" @removeItem="removeUrl">
        <ul>
          <li class="fields" v-for="(url, index) in note.urls">
            <Field
              placeholder="Label"
              class="block"
              v-model="url.label"
            ></Field>
            <Field placeholder="Url" class="block" v-model="url.url"></Field>
            <button
              type="button"
              class="btn icononly"
              @click="removeUrl(index)"
            >
              <span class="icon icon-minus"></span>
            </button>
          </li>
        </ul>
      </FieldItems>
      <FieldItems
        label="Shortcuts"
        @addItem="addShortcut"
        @removeItem="removeUrl"
      >
        <ul>
          <li v-for="(shortcut, index) in note.shortcut_keys">
            {{ getShortCut(shortcut) }}
            <button
              type="button"
              class="btn icononly"
              @click="removeShortcut(index)"
            >
              <span class="icon icon-minus"></span>
            </button>
          </li>
        </ul>
      </FieldItems>
      <FieldItems label="Chapters" @addItem="addNote">
        <ul>
          <li v-for="(n, index) in note.chapters">
            {{ getNote(n) }}
            <button
              type="button"
              class="btn icononly"
              @click="removeNote(index)"
            >
              <span class="icon icon-minus"></span>
            </button>
          </li>
        </ul>
      </FieldItems>
      <div class="buttons">
        <button
          class="confirm"
          type="button"
          :disabled="loading"
          @click="submit"
        >
          <span class="icon icon-pencil"></span>
          <span class="text" v-text="isNew ? 'Add' : 'Edit'"></span>
        </button>
        <button class="cancel" type="button" @click="cancel">
          <span class="text">Cancel</span>
        </button>
        <Loading v-if="loading"></Loading>
      </div>
    </form>
  </section>
</template>
@/models/note
