import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized
} from "vue-router";
import LoginForm from "@/components/LoginForm.vue";
import Profile from "@/components/user/Profile.vue";
import User from "@/components/user/User.vue";
import LearningsPage from "@/pages/LearningsPage.vue";
import BlogsPage from "@/pages/BlogsPage.vue";
import BlogPage from "./pages/BlogPage.vue";
import EditBlogPage from "./pages/EditBlogPage.vue";
import NotesPage from "./pages/NotesPage.vue";
import NotePage from "./pages/NotePage.vue";
import EditNotePage from "./pages/EditNotePage.vue";
import ShortcutsPage from "./pages/ShortcutsPage.vue";
import ShortcutPage from "./pages/ShortcutPage.vue";
import EditShortcutPage from "./pages/EditShortcutPage.vue";
import LessonPage from "./pages/LessonPage.vue";
import LessonsPage from "./pages/LessonsPage.vue";
import ApplicationsPage from "./pages/ApplicationsPage.vue";
import ApplicationPage from "./pages/ApplicationPage.vue";
import EditApplicationPage from "./pages/EditApplicationPage.vue";

import { Actions, store } from "./store";

const authenticate = async (
  to: RouteLocationNormalized,
  _: RouteLocationNormalized,
  next: (r?: any) => any
): Promise<void> => {
  // TODO: do not check auth api again, unless the session is older than x (server sets cookie at 3 hours)
  const auth = await store.dispatch(Actions.Auth);

  if (auth.isUser) {
    next();
    return;
  }
  next({
    path: "/login",
    props: { redirect: to.fullPath }
  });
};

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/learn",
      beforeEnter: authenticate,
      component: LearningsPage
    },
    {
      path: "/blog",
      component: BlogsPage
    },
    {
      path: "/blog/:id",
      component: BlogPage
    },
    {
      path: "/blog/add",
      beforeEnter: authenticate,
      component: EditBlogPage
    },
    {
      path: "/blog/:id/edit",
      beforeEnter: authenticate,
      component: EditBlogPage
    },
    {
      path: "/note",
      beforeEnter: authenticate,
      component: NotesPage
    },
    {
      path: "/note/add",
      beforeEnter: authenticate,
      component: EditNotePage
    },
    {
      path: "/note/:id",
      beforeEnter: authenticate,
      component: NotePage
    },
    {
      path: "/note/:id/edit",
      beforeEnter: authenticate,
      component: EditNotePage
    },
    {
      path: "/shortcut",
      beforeEnter: authenticate,
      component: ShortcutsPage
    },
    {
      path: "/shortcut/:id",
      beforeEnter: authenticate,
      component: ShortcutPage
    },
    {
      path: "/shortcut/:id/edit",
      beforeEnter: authenticate,
      component: EditShortcutPage
    },
    {
      path: "/lesson",
      beforeEnter: authenticate,
      component: LessonsPage
    },
    {
      path: "/lesson/:id",
      beforeEnter: authenticate,
      component: LessonPage
    },
    {
      path: "/application",
      beforeEnter: authenticate,
      component: ApplicationsPage
    },
    {
      path: "/application/:id",
      beforeEnter: authenticate,
      component: ApplicationPage
    },
    {
      path: "/application/:id/edit",
      beforeEnter: authenticate,
      component: EditApplicationPage
    },
    {
      path: "/profile",
      beforeEnter: authenticate,
      component: Profile
    },
    {
      path: "/user/:id",
      beforeEnter: authenticate,
      component: User
    },
    {
      path: "/login",
      component: LoginForm
    },
    {
      path: "/*",
      redirect: "/blog"
    },
    {
      path: "/",
      redirect: "/blog"
    }
  ]
});
