<script setup lang="ts">
import { Shortcut } from "@/models/shortcut";
import { PropType } from "vue";

const { shortcut } = defineProps({
  shortcut: {
    type: Object as PropType<Shortcut>,
    required: true
  }
});
</script>

<template>
  <span class="shortcut-key">
    <span class="key key-mod" v-if="shortcut.command_key">Command</span>
    <span v-if="shortcut.command_key"> + </span>
    <span class="key key-mod" v-if="shortcut.ctrl_key">Ctrl</span>
    <span v-if="shortcut.ctrl_key"> + </span>
    <span class="key key-mod" v-if="shortcut.alt_key">Alt</span>
    <span v-if="shortcut.alt_key"> + </span>
    <span class="key key-mod" v-if="shortcut.shift_key">Shift</span>
    <span v-if="shortcut.shift_key"> + </span>
    <span class="key" v-text="shortcut.shortcut_key"></span>
  </span>
  <a class="shortcut-key-link" :href.attr="`/shortcut/${shortcut.id}`">{{
    shortcut.name
  }}</a>
</template>
