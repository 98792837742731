<script lang="ts" setup>
import { onMounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store";
import Shortcuts from "@/components/shortcut/Shortcuts.vue";

const store = useStore();

onMounted(() => {
  store.dispatch(Actions.GetShortcuts);
});
</script>
<template><Shortcuts></Shortcuts></template>
