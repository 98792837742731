<script lang="ts" setup>
import ApplicationForm from "@/components/application/ApplicationForm.vue";
import { Actions } from "@/store";
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

const store = useStore();
const route = useRoute();

onMounted(() => {
  if (route.params.id) {
    store.dispatch(Actions.GetApplication, route.params.id);
  }
});
</script>
<template>
  <ApplicationForm></ApplicationForm>
</template>
