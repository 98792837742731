<script lang="ts" setup>
import { computed, markRaw, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { Actions } from "@/store";
import { Application, applicationValidations } from "@/models/application";
import Loading from "@/components/common/Loading.vue";
import Field from "@/components/common/Field.vue";
import { useLoading } from "../common/loading.composable";
import FieldItems from "../common/FieldItems.vue";
import { useRoute } from "vue-router";
import { Note } from "@/models/note";
import { useEvents, Event } from "@/core/services/events.composable";
import { DrawerMutations } from "@/stores/drawer.store";
import AddNoteForm from "../note/AddNoteForm.vue";
import { router } from "@/router";
import FieldLong from "../common/FieldLong.vue";

const store = useStore();
const route = useRoute();
const events = useEvents();

const note = computed<Note>(() => store.state.note);
const notes = computed<Note[]>(() => store.state.notes);

const application = reactive<Application>({
  ...store.state.application
});

onMounted(() => {
  if (!route.params.id) {
    store.dispatch(Actions.NewApplication);
    Object.assign(application, { ...store.state.application });
  }
  store.dispatch(Actions.GetNotes);
});

const isNew = computed<boolean>(() => application.id === -1);
const { loading, setLoading } = useLoading();

const $v = useVuelidate(applicationValidations, { application });
const emit = defineEmits(["close"]);

const submit = async () => {
  $v.value.$touch();
  if (!$v.value.$invalid) {
    setLoading(true);
    await store.dispatch(Actions.StoreApplication, application);

    setLoading(false);
    cancel();
  }
};

const cancel = () => {
  emit("close");

  if (application.id !== -1) {
    router.push(`/application/${application.id}`);
  } else {
    router.push(`/application`);
  }
};

const addMode = () => {
  application.modes = [...(application.modes || []), ""];
};

const removeMode = (index: number) => {
  application.modes.splice(index, 1);
};

const addNote = () => {
  store.commit(DrawerMutations.OpenDrawer, {
    drawer: markRaw(AddNoteForm),
    data: {
      notes: notes.value.filter(
        (s) => !(application.features || []).includes(s.id)
      )
    }
  });
};

events.on(Event.NoteCreated, () => {
  application.features = [...(application.features || []), note.value.id];
});

const removeNote = (index: number) => {
  application.features.splice(index, 1);
};

const getNote = (id: number): string => {
  return notes.value.find((s) => s.id === id)?.title || "unknown";
};
</script>
<template>
  <div class="form-sections">
    <section class="application-form">
      <form
        @submit.prevent="submit"
        :class="{ submitting: loading }"
        v-if="application"
      >
        <h2 v-text="isNew ? 'Add application' : 'Edit application'"></h2>
        <p v-if="isNew">Fill out the details of the application</p>
        <p v-else>Edit the details of the application</p>

        <Field
          label="Name"
          :validation="$v.application.name"
          v-model="application.name"
        ></Field>
        <FieldLong
          label="Description"
          :validation="$v.application.description"
          v-model="application.description"
        ></FieldLong>
        <Field
          label="Website"
          :validation="$v.application.website"
          v-model="application.website"
        ></Field>
        <FieldItems label="Modes" @addItem="addMode">
          <ul>
            <li class="fields" v-for="(_, index) in application.modes">
              <Field
                placeholder="Name"
                class="block"
                v-model="application.modes[index]"
              ></Field>

              <button
                type="button"
                class="btn icononly"
                @click="removeMode(index)"
              >
                <span class="icon icon-minus"></span>
              </button>
            </li>
          </ul>
        </FieldItems>
        <div class="buttons">
          <button
            class="confirm"
            type="button"
            :disabled="loading"
            @click="submit"
          >
            <span class="icon icon-pencil"></span>
            <span class="text" v-text="isNew ? 'Add' : 'Edit'"></span>
          </button>
          <button class="cancel" type="button" @click="cancel">
            <span class="text">Cancel</span>
          </button>
          <Loading v-if="loading"></Loading>
        </div>
      </form>
    </section>
    <section class="application-mode-form">
      <form>
        <h2>Features</h2>
        <FieldItems label="Features" @addItem="addNote">
          <ul>
            <li v-for="(note, index) in application.features">
              {{ getNote(note) }}
              <button
                type="button"
                class="btn icononly"
                @click="removeNote(index)"
              >
                <span class="icon icon-minus"></span>
              </button>
            </li>
          </ul>
        </FieldItems>
      </form>
    </section>
  </div>
</template>
@/models/application
