<script setup lang="ts">
import { computed, markRaw, onMounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store";
import { Learning as LearningModel } from "@/models/learning";
import { DrawerMutations } from "@/stores/drawer.store";
import LearningForm from "./LearningForm.vue";
import LearningCalendar from "./LearningCalendar.vue";
import { CalendarData, CalendarDay } from "@/models/calendar";

const store = useStore();
const learnings = computed<LearningModel[]>(() => store.state.learnings);
const calendar = computed<CalendarData>(() => store.state.calendar);

onMounted(() => {
  store.dispatch(Actions.GetCalendar);
  store.dispatch(Actions.GetLearnings);
});

const editLearning = (day: CalendarDay, learnings: LearningModel[]) => {
  if (!learnings?.length) {
    store.dispatch(Actions.NewLearning);
  }
  store.commit(DrawerMutations.OpenDrawer, {
    drawer: markRaw(LearningForm),
    data: {
      date: day.internal
    }
  });
};
</script>
<template>
  <LearningCalendar
    v-if="calendar"
    :calendar="calendar"
    :learnings="learnings"
    @edit="editLearning"
  />
  <!-- <section class="learnings">
    <Learning v-for="learning in learnings" :learning="learning" listed>
    </Learning>
  </section> -->
</template>
