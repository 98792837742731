<script lang="ts" setup>
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { Actions } from '@/store';

const store = useStore();
const route = useRoute();

const user = computed(() => store.state.user);

onMounted(() => {
  const id = route.params.id;
  store.dispatch(Actions.GetUser, id);
});
</script>
<template>
  <section class="user">
    <h2>User</h2>
    <ul v-if="user">
      <li v-text="user.first_name"></li>
      <li v-text="user.last_name"></li>
      <li v-text="user.email"></li>
    </ul>
  </section>
</template>
