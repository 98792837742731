<script lang="ts" setup>
import { onMounted, PropType, reactive } from 'vue';
import { useStore } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { User, userValidations } from '@/models/user';
import Field from '@/components/common/Field.vue';
import UserFields from '@/components/user/UserFields.vue';
import { useLoading } from '../common/loading.composable';
import { Actions } from '@/store';

const store = useStore();

defineProps({
  data: {
    type: Object as PropType<User>,
  },
});

const emit = defineEmits(['close']);

const { loading, setLoading } = useLoading();
let profile = reactive<User>({ ...store.state.profile });
const $v = useVuelidate(userValidations, profile);

onMounted(async () => {
  await store.dispatch(Actions.GetProfile);
});

const submit = async () => {
  setLoading(true);
  await store.dispatch(Actions.UpdateProfile, profile);
  setLoading(false);
  cancel();
};

const cancel = () => {
  emit('close');
};
</script>
<template>
  <section class="drawer-content user-form">
    <form
      @submit.prevent="submit"
      :class="{ submitting: loading }"
      v-if="profile"
    >
      <h2>Edit your profile</h2>
      <p>Edit the details of your profile</p>
      <Field
        label="Email"
        readonly
        :validation="$v.email"
        v-model="profile.email"
      ></Field>
      <UserFields
        v-model="profile"
        :validation="$v"
        :loading="loading"
      ></UserFields>
      <div class="buttons">
        <button class="confirm" type="submit" :disabled="loading">
          <span class="icon icon-pencil"></span>
          <span class="text">Edit</span>
        </button>
        <button class="cancel" type="button" @click="cancel">
          <span class="text">Cancel</span>
        </button>
      </div>
    </form>
  </section>
</template>
