import { DocumentSelection } from "../types";
import { getBlockElement, getParentElement } from "./html.util";

export const getDocumentSelection = (): DocumentSelection => {
  const selection = getSelection();

  let startNode = null;
  let startBlockElement = null;
  let endNode = null;
  let endBlockElement = null;
  const nodeRange: HTMLElement[] = [];
  let blockSelection = false;

  if (selection?.anchorNode) {
    startNode = getParentElement(selection.anchorNode);
    startBlockElement = getBlockElement(startNode);
    nodeRange.push(startNode);
  }
  if (selection?.focusNode) {
    endNode = getParentElement(selection.focusNode);
    endBlockElement = getBlockElement(endNode);
  }

  if (
    startNode &&
    endNode &&
    startNode !== endNode &&
    startNode.parentElement === endNode.parentElement
  ) {
    blockSelection = true;
    let element: Element | null = startNode;
    while (element && element !== endNode) {
      element = element.nextElementSibling;
      if (element) {
        nodeRange.push(element as HTMLElement);
      }
    }
  }

  return {
    startNode,
    startBlockElement,
    endNode,
    endBlockElement,
    nodeRange,
    blockSelection
  };
};
