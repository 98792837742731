<script lang="ts" setup>
import { fieldProps } from "./field.composable";
import Message from "@/components/common/Message.vue";
import { getMessage } from "@/core/validation";

const props = defineProps({
  ...fieldProps
});

const emit = defineEmits(["add-item", "remove-item"]);

const add = () => {
  emit("add-item");
};
const remove = () => {
  emit("remove-item");
};
</script>
<template>
  <div
    class="field field-items"
    :class="[
      { invalid: props.validation.$invalid && props.validation.$dirty },
      props.styling
    ]"
  >
    <label v-if="!props.hideLabel">
      {{ label }}
      <sup
        v-if="
          props.validation &&
          props.validation.$invalid &&
          !props.validation.$dirty
        "
        >*</sup
      >
    </label>
    <slot />
    <button type="button" class="btn icononly" @click="add">
      <span class="icon icon-plus"></span>
    </button>
    <Message
      type="alert"
      :styling="props.messageStyling"
      :message="getMessage(props.validation)"
      :show="props.validation.$invalid && props.validation.$dirty"
      @dismiss="props.validation.$reset()"
    ></Message>
  </div>
</template>
