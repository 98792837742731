import {
  BaseService,
  Status,
  DataResponse,
  MessageData,
  MessageResponse
} from "@/core/services/base.service";
import { Shortcut } from "@/models/shortcut";

class ShortcutService extends BaseService {
  private url: string = "/api/shortcut";
  private cached: Shortcut[] = [];

  constructor() {
    super();
  }

  public new(): Shortcut {
    return new Shortcut({
      id: -1,
      name: "",
      type: "shortcut",
      description: "",
      application_id: -1,
      mode: "",
      shortcut_key: "",
      ctrl_key: false,
      shift_key: false,
      alt_key: false,
      command_key: false,
      left_mouse_button: false,
      right_mouse_button: false,
      modifiers: []
    });
  }

  public async getList(): Promise<DataResponse<Shortcut[]>> {
    if (this.hasCache()) {
      return {
        status: true,
        data: this.getCache()
      };
    }
    const res = await this.$http.get<Shortcut[]>(`${this.url}`);
    this.setCache(res.data.map((d) => new Shortcut(d)));
    return {
      status: res.status === Status.Ok,
      data: this.getCache()
    };
  }

  public async get(id: number): Promise<DataResponse<Shortcut>> {
    const res = await this.$http.get<Shortcut>(`${this.url}/${id}`);
    return {
      status: res.status === Status.Ok,
      data: new Shortcut(res.data)
    };
  }

  public async post(shortcut: Shortcut): Promise<DataResponse<Shortcut>> {
    const res = await this.$http.post<Shortcut>(this.url, {
      ...shortcut,
      date_created: new Date().toISOString()
    });
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      data: new Shortcut(res.data)
    };
  }

  public async put(shortcut: Shortcut): Promise<DataResponse<Shortcut>> {
    const res = await this.$http.put<Shortcut>(
      `${this.url}/${shortcut.id}`,
      shortcut
    );
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      data: new Shortcut(res.data)
    };
  }

  public async delete(id: number): Promise<MessageResponse> {
    const res = await this.$http.delete<MessageData>(`${this.url}/${id}`);
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      message: res.data.message
    };
  }

  private getCache(): Shortcut[] {
    return this.cached;
  }

  private setCache(list: Shortcut[]): void {
    this.cached = list;
  }

  private hasCache(): boolean {
    return this.cached.length !== 0;
  }

  private clearCache(): void {
    this.setCache([]);
  }
}

export default new ShortcutService();
