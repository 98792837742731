<script lang="ts" setup>
import { onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Actions } from "@/store";
import Shortcut from "@/components/shortcut/Shortcut.vue";

const store = useStore();
const route = useRoute();

onMounted(() => {
  store.dispatch(Actions.GetShortcut, route.params.id);
});
</script>
<template><Shortcut></Shortcut></template>
