<script lang="ts" setup>
import { computed, onMounted, PropType, ref, watch } from "vue";
import { fieldProps } from "./field.composable";

export interface Item {
  label: string;
  value: string | any;
}

const props = defineProps({
  ...fieldProps,
  modelValue: {
    required: true
  },
  items: {
    type: Array as PropType<Item[]>,
    required: true
  }
});

const emit = defineEmits(["update:modelValue"]);

const item = ref<any>(
  props.items.find((v) => v.value === props.modelValue) || null
);

watch(
  props,
  () => {
    item.value = props.items.find((v) => v.value === props.modelValue) || null;
  },
  { deep: true }
);

const open = ref<boolean>(false);
const focussed = ref<number>(0);

const openClass = computed<string>(() =>
  open.value ? "choice-input-open" : ""
);

const setItem = () => {
  item.value = props.items.find((i) => i.value === props.modelValue);
};

const update = () => {
  emit("update:modelValue", item.value.value);
};

const toggleList = () => {
  open.value = !open.value;
  focussed.value =
    open.value && item.value
      ? props.items.findIndex((i) => i.value === item.value.value)
      : -1;
};

const close = () => {
  open.value = false;
};

const blur = () => {
  // prevent the blur from closing before a choose function has had time to work
  setTimeout(() => close(), 250);
};

const choose = () => {
  if (open.value) {
    chooseItem(focussed.value);
  }
};

const chooseItem = (index: number) => {
  const i = props.items[index];
  item.value = i;
  update();
  close();
};

const focusClass = (index: number) => {
  return index === focussed.value ? "choice-focus" : "";
};

const next = () => {
  if (!open.value) {
    toggleList();
    return;
  }
  focussed.value++;
  if (focussed.value > props.items.length - 1) {
    focussed.value = 0;
  }
};

const previous = () => {
  focussed.value--;
  if (focussed.value < 0) {
    focussed.value = props.items.length - 1;
  }
};

onMounted(() => {
  setItem();
});
</script>
<template>
  <div :class="'field choice-input ' + openClass">
    <label v-if="!props.hideLabel">
      {{ label }}
      <sup
        v-if="
          props.validation &&
          props.validation.$invalid &&
          !props.validation.$dirty
        "
        >*</sup
      >
    </label>
    <div class="choice-field-content">
      <input
        class="visible"
        type="text"
        readonly
        :value="item ? item.label : ''"
        @click="toggleList"
        @keydown.prevent.esc="close"
        @keydown.prevent.enter="choose"
        @keydown.prevent.up="previous"
        @keydown.prevent.down="next"
      />
      <input
        class="invisible"
        type="text"
        tabindex="-1"
        readonly
        :value="item"
        @blur.prevent="blur"
        @click="toggleList"
        @keydown.prevent.esc="close"
        @keydown.prevent.space="choose"
        @keydown.prevent.enter="choose"
        @keydown.prevent.up="previous"
        @keydown.prevent.down="next"
      />
      <ul class="choice-list" v-if="open">
        <li
          v-for="(item, index) in items"
          :class="focusClass(index)"
          :value="item.value"
          v-text="item.label"
          :key="item.label"
          @click.stop.prevent="chooseItem(index)"
        ></li>
      </ul>
    </div>
  </div>
</template>
