class PutRequest extends Request {
  constructor(url: string, body: Object) {
    super(url, {
      method: "PUT",
      body: JSON.stringify(body)
    });
  }
}

class PostRequest extends Request {
  constructor(url: string, body: Object) {
    super(url, {
      method: "POST",
      body: JSON.stringify(body)
    });
  }
}

class DeleteRequest extends Request {
  constructor(url: string) {
    super(url, {
      method: "DELETE"
    });
  }
}

export interface AjaxResponse<T> {
  status: number;
  data: T;
}

const http = async (request: Request): Promise<Response> => {
  // request interceptors
  const response = await fetch(request);
  // response interceptors
  if (response.status === 401 || response.status === 500) {
    document.location.href = "/login";
    return new Response();
  }
  return response;
};

export class Ajax {
  constructor() {}

  async get<T>(url: string): Promise<AjaxResponse<T>> {
    const request = new Request(url);
    const response = await http(request);

    const data = response.status === 200 ? await response.json() : undefined;
    return {
      status: response.status,
      data
    };
  }

  async put<T>(url: string, body: Object): Promise<AjaxResponse<T>> {
    const request = new PutRequest(url, body);
    const response = await http(request);

    const data = response.status === 200 ? await response.json() : undefined;
    return {
      status: response.status,
      data
    };
  }

  async post<T>(url: string, body: Object): Promise<AjaxResponse<T>> {
    const request = new PostRequest(url, body);
    const response = await http(request);

    const data = response.status === 200 ? await response.json() : undefined;
    return {
      status: response.status,
      data
    };
  }

  async delete<T>(url: string): Promise<AjaxResponse<T>> {
    const request = new DeleteRequest(url);
    const response = await http(request);

    const data = response.status === 200 ? await response.json() : undefined;
    return {
      status: response.status,
      data
    };
  }
}
