<script setup lang="ts">
import { Actions } from "@/store";
import { computed, onMounted } from "vue";
import { useStore } from "vuex";

const store = useStore();
const lessons = computed(() => store.state.lessons);

onMounted(() => {
  store.dispatch(Actions.GetLessons);
});
</script>
<template>
  <section>
    <h2>Lessons</h2>
    <ul>
      <li v-for="lesson in lessons" class="lesson">
        <h4>
          <a target="_blank" :href.attr="lesson.url">{{ lesson.name }}</a>
        </h4>
        <p>{{ lesson.description }}</p>
        <p>{{ lesson.author }} <i v-text="lesson.year"></i></p>
      </li>
    </ul>
  </section>
</template>
