<script lang="ts" setup>
import { PropType, computed, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { Actions } from "@/store";
import { Learning, learningValidations } from "@/models/learning";
import Loading from "@/components/common/Loading.vue";
import Field from "@/components/common/Field.vue";
import FieldSwitch from "@/components/common/FieldSwitch.vue";
import FieldChoice, { Item } from "@/components/common/FieldChoice.vue";
import { useLoading } from "../common/loading.composable";

import { Application } from "@/models/application";
import { formatDate } from "date-fns";

const props = defineProps({
  data: {
    type: Object as PropType<{ date: string }>,
    required: true
  }
});

const store = useStore();
const learning = reactive<Learning>({
  ...store.state.learning
});

const isNew = computed<boolean>(() => learning.id === -1);
const applicationOptions = computed<Item[]>(() => [
  { label: "None", value: undefined },
  ...store.state.applications.map((app: Application) => ({
    label: app.name,
    value: app.id
  }))
]);
const { loading, setLoading } = useLoading();

onMounted(() => {
  store.dispatch(Actions.GetApplications);

  store.dispatch(Actions.NewLearning);
  Object.assign(learning, {
    ...store.state.learning,
    date: formatDate(Date.parse(props.data.date), "yyyy-MM-dd")
  });
});
const $v = useVuelidate(learningValidations, { learning });
const emit = defineEmits(["close"]);

const submit = async () => {
  $v.value.$touch();
  if (!$v.value.$invalid) {
    setLoading(true);
    await store.dispatch(Actions.StoreLearning, learning);

    setLoading(false);
    store.dispatch(Actions.NewLearning);
    Object.assign(learning, { ...store.state.learning });

    cancel();
  }
};

const cancel = () => {
  emit("close");
};
</script>
<template>
  <section class="drawer-content learning-form">
    <form
      @submit.prevent="submit"
      :class="{ submitting: loading }"
      v-if="learning"
    >
      <h2 v-text="isNew ? 'Add learning' : 'Edit learning'"></h2>
      <p v-if="isNew">Fill out the details of the learning</p>
      <p v-else>Edit the details of the learning</p>

      <Field
        label="Activity"
        :validation="$v.learning.name"
        v-model="learning.activity"
      ></Field>
      <Field
        label="Date"
        :validation="$v.learning.date"
        v-model="learning.date"
        type="date"
        styling="field-date"
      ></Field>
      <FieldChoice
        label="Application"
        v-model="learning.application_id"
        :items="applicationOptions"
      ></FieldChoice>

      <FieldSwitch label="Learned" v-model="learning.learned"></FieldSwitch>

      <div class="buttons">
        <button
          class="confirm"
          type="button"
          :disabled="loading"
          @click="submit"
        >
          <span class="icon icon-pencil"></span>
          <span class="text" v-text="isNew ? 'Add' : 'Edit'"></span>
        </button>
        <button class="cancel" type="button" @click="cancel">
          <span class="text">Cancel</span>
        </button>
        <Loading v-if="loading"></Loading>
      </div>
    </form>
  </section>
</template>
@/models/learning
