<script lang="ts" setup>
import { computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store";
import { Blog as BlogModel } from "@/models/blog";
import Blog from "@/components/blog/Blog.vue";

const store = useStore();
const route = useRoute();

const blog = computed<BlogModel>(() => store.state.blog);

onMounted(() => {
  store.dispatch(Actions.GetBlog, route.params.id);
});
</script>
<template><Blog :blog="blog" v-if="blog"></Blog></template>
