<script setup lang="ts">
import { computed, markRaw, onMounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store";
import { Application } from "@/models/application";
import { Note } from "@/models/note";
import ApplicationForm from "./ApplicationForm.vue";
import { DrawerMutations } from "@/stores/drawer.store";
import { useRouter } from "vue-router";
import { Shortcut } from "@/models/shortcut";
import Filter from "../common/Filter.vue";

const store = useStore();
const router = useRouter();
const applications = computed<Application[]>(
  () => store.getters.filteredApplications
);
const notes = computed<Note[]>(() => store.state.notes);
const shortcuts = computed<Shortcut[]>(() => store.state.shortcuts);

onMounted(() => {
  store.dispatch(Actions.GetApplications);
  store.dispatch(Actions.GetNotes);
  store.dispatch(Actions.GetShortcuts);
});

const getNotes = (ids: number[] = []) =>
  notes.value.filter((n: Note) => ids.includes(n.id));

const getShortcuts = (id: number) =>
  shortcuts.value.filter((s: Shortcut) => s.application_id === id);

const addApplication = () => {
  store.commit(DrawerMutations.OpenDrawer, {
    drawer: markRaw(ApplicationForm)
  });
};

const navigate = (id: number) => {
  router.push(`/application/${id}`);
};
</script>
<template>
  <div class="buttons">
    <Filter />
    <button type="button" class="option" @click="addApplication">
      <span class="icon icon-pencil"></span> Add application
    </button>
  </div>
  <section v-for="application in applications" class="application">
    <h2 v-text="application.name" @click="navigate(application.id)"></h2>
    <p v-text="application.description"></p>
    <div class="columns">
      <ul class="col half">
        <li>{{ (application.modes || []).length }} Modes</li>
        <li>{{ getNotes(application.features).length }} Features</li>
        <li>{{ getShortcuts(application.id).length }} Shortcuts</li>
      </ul>
    </div>
  </section>
</template>
