<script lang="ts" setup>
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store";
import ShortcutForm from "@/components/shortcut/ShortcutForm.vue";

const store = useStore();
const route = useRoute();

onMounted(async () => {
  if (route.params.id) {
    await store.dispatch(Actions.GetShortcut, route.params.id);
  }
});
</script>
<template><ShortcutForm></ShortcutForm></template>
