<script lang="ts" setup>
import { Mutations } from "@/store";
import { ref } from "vue";
import { useStore } from "vuex";

const input = ref(null);
const store = useStore();
const filter = ref<string>(store.state.filter);

const update = (evt: Event) => {
  store.commit(Mutations.UpdateFilter, filter.value);
};
</script>
<template>
  <form @submit.prevent="update" class="filter field inline">
    <label for="filter">Filter</label>
    <input
      id="filter"
      ref="input"
      v-model="filter"
      @keydown="update"
      @keyup="update"
    />
  </form>
</template>
