import { createApp } from "vue";
import LearnApp from "./LearnApp.vue";
import { router } from "./router";
import { store } from "./store";
import RaiseOrdinal from "./directives/raise-ordinal";
import AutoFocus from "./directives/auto-focus";
import ClickOutside from "./directives/click-outside";

const app = createApp(LearnApp).use(router).use(store);

app.directive("raise-ordinal", RaiseOrdinal);
app.directive("auto-focus", AutoFocus);
app.directive("click-outside", ClickOutside);

app.mount("#app");
