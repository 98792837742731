<script lang="ts" setup>
import { PropType } from "vue";
import { Result } from "@/models/results.model";
import { Project } from "@/models/project";

const { result } = defineProps({
  result: {
    type: Object as PropType<Result<Project>>,
    required: true
  }
});
</script>
<template>
  <li class="search-result">
    <router-link :to="`/project/${result.item.id}`"
      >{{ result.item.name }}
    </router-link>
    <div>
      {{ result.item.description }}
    </div>
  </li>
</template>
@/models/learning
