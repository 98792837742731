import { required } from "@vuelidate/validators";

interface Url {
  label: string;
  url: string;
}

export class Note {
  public id: number;
  public date_created: string;
  public date_modified: string;
  public title: string;
  public description: string;
  public shortcut_keys: number[];
  public urls: Url[];
  public chapters: number[];

  constructor(data: Note) {
    this.id = data.id;
    this.date_created = data.date_created;
    this.date_modified = data.date_modified;
    this.title = data.title;
    this.description = data.description;
    this.shortcut_keys = data.shortcut_keys;
    this.urls = data.urls;
    this.chapters = data.chapters;
  }
}

export const getNoteData = (note: Note) => {
  return [
    (note.title || "").toLowerCase(),
    (note.description || "").toLowerCase(),
    ...note.urls.reduce(
      (acc: string[], url) => [...acc, url.url, url.label.toLowerCase()],
      []
    )
  ];
};

export const noteValidations = {
  note: {
    date: {},
    title: { required },
    description: { required }
  }
};
