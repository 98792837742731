import { required } from "@vuelidate/validators";

export class Learning {
  public id: number;
  public date: string;
  public application_id?: number;
  public learned: boolean;
  public activity: string;

  constructor(data: Learning) {
    this.id = data.id;
    this.date = data.date;
    this.application_id = data.application_id;
    this.learned = data.learned;
    this.activity = data.activity;
  }
}

export const learningValidations = {
  learning: {
    date: {},
    activity: { required }
  }
};
