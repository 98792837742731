<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";
import { Note } from "@/models/note";
import { Shortcut } from "@/models/shortcut";
import { convertMarkdown } from "@/markdown/util/markdown.util";
import { Mutations } from "@/store";
import { useRouter } from "vue-router";
import ShortcutKey from "../shortcut/ShortcutKey.vue";
import { Application } from "@/models/application";

const store = useStore();
const router = useRouter();
const applications = computed<Application[]>(() => store.state.applications);
const notes = computed<Note[]>(() => store.state.notes);
const note = computed<Note>(() => store.state.note);
const shortcuts = computed(() => store.state.shortcuts);

const getNotes = (ids: number[]) =>
  notes.value.filter((n: Note) => ids.includes(n.id));

const parentNote = computed(() =>
  notes.value.find((n: Note) => n.chapters.includes(note.value.id))
);

const parentApp = computed(() =>
  applications.value.find((a: Application) =>
    parentNote.value
      ? (a.features || []).includes(parentNote.value.id)
      : (a.features || []).includes(note.value.id)
  )
);
const getShortcuts = (note: Note): Shortcut[] =>
  shortcuts.value.filter((s: Shortcut) => note.shortcut_keys.includes(s.id));

const edit = () => {
  store.commit(Mutations.SetNote, note.value);
  router.push(`/note/${note.value.id}/edit`);
};
const navigate = (n: Note) => {
  store.commit(Mutations.SetNote, n);
  router.push(`/note/${n.id}`);
};
const navigateApp = (n: Application) => {
  store.commit(Mutations.SetApplication, n);
  router.push(`/application/${n.id}`);
};
</script>
<template>
  <div class="buttons">
    <button type="button" class="option" @click="edit">
      <span class="icon icon-pencil"></span> Edit
    </button>
  </div>
  <section class="note" v-if="note">
    <h2>
      <router-link
        v-if="parentApp"
        :to="`/note/${parentApp.id}`"
        @click.prevent="navigateApp(parentApp)"
        v-text="parentApp.name"
      ></router-link>
      <span v-if="parentApp"> / </span>
      <router-link
        v-if="parentNote"
        :to="`/note/${parentNote.id}`"
        @click.prevent="navigate(parentNote)"
        v-text="parentNote.title"
      ></router-link>
      <span v-if="parentNote"> / </span>
      <span v-text="note.title"></span>
    </h2>
    <div class="columns">
      <div class="col threefifths">
        <div v-html="convertMarkdown(note.description)"></div>
        <div v-for="n in getNotes(note.chapters)">
          <h3 v-text="n.title"></h3>
          <div v-html="convertMarkdown(n.description)"></div>
        </div>
        <div v-if="note.urls.length">
          <h3>Related links</h3>
          <ul>
            <li v-for="url in note.urls">
              <a :href.attr="url.url" v-text="url.label"></a>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="note.shortcut_keys.length" class="col twofifths">
        <h3>Related shortcut keys</h3>
        <div class="columns">
          <ul class="col half">
            <li v-for="shortcut in getShortcuts(note)">
              <ShortcutKey :shortcut="shortcut"></ShortcutKey>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>
