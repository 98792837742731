import {
  getElement,
  isHeader,
  isParagraph,
  isList,
  isOrderedList,
  isListItem,
  isPre,
  isBold,
  isItalic,
  isCode,
  isLink,
  isText,
  isImage
} from "@/markdown/util/html.util";

// converts HTML to Markdown
export const convertHTML = (parent: HTMLElement): string => {
  let markdown = "";
  for (let index = 0; index < parent.childNodes.length; index++) {
    const node = parent.childNodes[index];
    const el = getElement(node);

    // header
    if (isHeader(node) && el) {
      markdown = `${markdown}#${convertHTML(el)}\n`;
    }
    // paragraph
    if (isParagraph(node) && el) {
      markdown = `${markdown}${convertHTML(el)}\n`;
    }
    // list
    if (isList(node) && el) {
      markdown = `${markdown}\n${convertHTML(el)}\n`;
    }
    // list item
    if (isListItem(node) && el) {
      markdown = `${markdown}${getListType(
        el.parentElement!,
        index
      )} ${convertHTML(el)}\n`;
    }
    // paragraph
    if (isPre(node) && el) {
      markdown = `${markdown}\`\`\`\n${convertHTML(el)}\n\`\`\`\n`;
    }
    // bold
    if (isBold(node) && el) {
      markdown = `${markdown}**${convertHTML(el)}**`;
    }
    // italic
    if (isItalic(node) && el) {
      markdown = `${markdown}_${convertHTML(el)}_`;
    }
    // code
    if (isCode(node) && el) {
      markdown = `${markdown}\`${convertHTML(el)}\``;
    }
    // link
    if (isLink(node) && el) {
      markdown = `${markdown}[${convertHTML(el)}](${el.getAttribute("href")})`;
    }
    // text
    if (isText(node)) {
      markdown = `${markdown}${node.textContent}`;
    }
    // image
    if (isImage(node)) {
      markdown = `${markdown}${(node as HTMLElement).outerHTML}`;
    }
  }

  return markdown;
};

const getListType = (el: HTMLElement, index: number): string => {
  return isOrderedList(el) ? `  ${index + 1}.` : "  -";
};

// converts Markdown to HTML
export const convertMarkdown = (
  markdown: string,
  root: boolean = true,
  parent: HTMLElement = document.createElement("div")
): string => {
  const parentTag = parent.tagName.toLowerCase();

  if (markdown.includes("\n")) {
    let lines = markdown.split("\n");

    for (let index = 0; index < lines.length; index++) {
      const line = lines[index];

      if (line?.startsWith("# ")) {
        // header
        const h4 = document.createElement("h4");
        h4.innerHTML = convertMarkdown(line.substring(2), false, h4);
        parent.appendChild(h4);
      } else if (line && isListLine(line) && !isList(parent)) {
        const { list, newLines } = createList(
          "ul",
          [line, ...lines.slice(index + 1)],
          isListLine
        );
        parent.appendChild(list);
        lines = newLines;
        index = 0;
      } else if (line && isOrderedListLine(line) && !isOrderedList(parent)) {
        const { list, newLines } = createList(
          "ol",
          [line, ...lines.slice(index + 1)],
          isOrderedListLine
        );
        parent.appendChild(list);
        lines = newLines;
        index = 0;
      } else if (
        (line && isListLine(line) && isList(parent)) ||
        (isOrderedListLine(line) && isOrderedList(parent))
      ) {
        const item = document.createElement("li");
        item.innerHTML = convertMarkdown(getListLineText(line), false, item);
        parent.appendChild(item);
      } else if (line) {
        const p = document.createElement("p");
        p.innerHTML = convertMarkdown(line, false, p);
        parent.appendChild(p);
      }
    }
  } else if (root) {
    if (parentTag === "ul" || parentTag === "ol") {
      const item = document.createElement("li");
      item.innerHTML = convertMarkdown(markdown, false, item);
      parent.appendChild(item);
    } else {
      const p = document.createElement("p");
      p.innerHTML = convertMarkdown(markdown, false, p);
      parent.appendChild(p);
    }
  } else {
    let match = markdown.match(/(?<=\_).[^\>]*?(?=\_)/g);
    // italics
    while (match) {
      markdown = markdown.replace(`_${match[0]}_`, `<i>${match[0]}</i>`);
      match = markdown.match(/(?<=\_).[^\>]*?(?=\_)/g);
    }
    // bold
    match = markdown.match(/(?<=\*\*).*?(?=\*\*)/g);
    while (match) {
      markdown = markdown.replace(`\*\*${match[0]}\*\*`, `<b>${match[0]}</b>`);
      match = markdown.match(/(?<=\*\*).*?(?=\*\*)/g);
    }
    // code
    match = markdown.match(/(?<=\`).*?(?=\`)/g);
    while (match) {
      markdown = markdown.replace(
        `\`${match[0]}\``,
        `<code>${match[0]}</code>`
      );
      match = markdown.match(/(?<=\`).*?(?=\`)/g);
    }
    // link
    match = markdown.match(/(?<=\[)(.*?)(?=\])\]\((?<=\()(.*?)(?=\))/i);
    while (match) {
      markdown = markdown.replace(
        `[${match[1]}](${match[2]})`,
        `<a href="${match[2]}">${match[1]}</a>`
      );
      match = markdown.match(/(?<=\`).*?(?=\`)/g);
    }
    return markdown;
  }

  return parent.innerHTML;
};

const createList = (
  tagName: string,
  lines: string[],
  filter: (line: string) => boolean
): { list: HTMLElement; newLines: string[] } => {
  const list = document.createElement(tagName);
  const listLines = [];
  while (lines[0] && filter(lines[0])) {
    listLines.push(lines.shift()!);
  }
  list.innerHTML = convertMarkdown(listLines.join("\n"), true, list);

  return { list, newLines: lines };
};

const isListLine = (line: string): boolean => line.startsWith("  - ");
const isOrderedListLine = (line: string): boolean =>
  !!line.match(/^\s\s\d+\.\s.+/g);

export const getListLineText = (line: string): string =>
  !!line.match(/^\s\s\d+\.\s.+/g)
    ? /^\s\s\d+\.?\s(.+)/g.exec(line)![1]
    : line.substring(4);
