<script setup lang="ts">
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Actions } from "@/store";
import { Lesson } from "@/models/lesson";

const store = useStore();
const route = useRoute();
const lesson = computed<Lesson>(() => store.state.lesson);

onMounted(() => {
  store.dispatch(Actions.GetLesson, route.params.id);
});
</script>
<template>
  <section class="lesson">
    <h2 v-text="lesson.name"></h2>
  </section>
</template>
