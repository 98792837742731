<script lang="ts" setup>
import { computed, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { Actions } from "@/store";
import { Lesson, lessonValidations } from "@/models/lesson";
import Loading from "@/components/common/Loading.vue";
import Field from "@/components/common/Field.vue";
import { useLoading } from "../common/loading.composable";
import FieldChoice, { Item } from "../common/FieldChoice.vue";
import { Application } from "@/models/application";
import FieldNumber from "../common/FieldNumber.vue";
import FieldLong from "../common/FieldLong.vue";

const store = useStore();
const lesson = reactive<Lesson>({
  ...store.state.lesson
});
const isNew = computed<boolean>(() => lesson.id === -1);
const applicationOptions = computed<Item[]>(() => [
  { label: "None", value: undefined },
  ...store.state.applications.map((app: Application) => ({
    label: app.name,
    value: app.id
  }))
]);
const { loading, setLoading } = useLoading();

onMounted(() => {
  store.dispatch(Actions.GetApplications);

  store.dispatch(Actions.NewLesson);
  Object.assign(lesson, { ...store.state.lesson });
});

const $v = useVuelidate(lessonValidations, { lesson });
const emit = defineEmits(["close"]);

const submit = async () => {
  $v.value.$touch();
  if (!$v.value.$invalid) {
    setLoading(true);
    await store.dispatch(Actions.StoreLesson, lesson);

    setLoading(false);
    await store.dispatch(Actions.NewLesson);
    cancel();
  }
};

const cancel = () => {
  emit("close");
};
</script>
<template>
  <section class="drawer-content lesson-form">
    <form
      @submit.prevent="submit"
      :class="{ submitting: loading }"
      v-if="lesson"
    >
      <h2 v-text="isNew ? 'Add lesson' : 'Edit lesson'"></h2>
      <p v-if="isNew">Fill out the details of the lesson</p>
      <p v-else>Edit the details of the lesson</p>

      <Field
        label="Name"
        :validation="$v.lesson.name"
        v-model="lesson.name"
      ></Field>
      <Field
        label="Author"
        :validation="$v.lesson.author"
        v-model="lesson.author"
      ></Field>
      <FieldLong
        label="Description"
        :validation="$v.lesson.description"
        v-model="lesson.description"
      ></FieldLong>
      <Field
        label="Url"
        :validation="$v.lesson.url"
        v-model="lesson.url"
      ></Field>
      <FieldNumber
        label="Year"
        :validation="$v.lesson.year"
        v-model.number="lesson.year"
      ></FieldNumber>
      <FieldChoice
        label="Application"
        v-model="lesson.application_id"
        :items="applicationOptions"
      ></FieldChoice>
      <div class="buttons">
        <button
          class="confirm"
          type="button"
          :disabled="loading"
          @click="submit"
        >
          <span class="icon icon-pencil"></span>
          <span class="text" v-text="isNew ? 'Add' : 'Edit'"></span>
        </button>
        <button class="cancel" type="button" @click="cancel">
          <span class="text">Cancel</span>
        </button>
        <Loading v-if="loading"></Loading>
      </div>
    </form>
  </section>
</template>
@/models/lesson
