import { format } from "date-fns";

export const formatMonth = (value: number | string): string =>
  format(new Date(value), "MMMM yyyy");

export const formatDate = (value: number | string): string =>
  format(new Date(value), "dd-MM-yyyy");

const nth = (d: number) => {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const formatDateLong = (value: number | string): string => {
  const d = new Date(value);
  const ordinal = nth(d.getDate());

  return format(d, "MMMM d__ yyyy").replace("__", ordinal);
};

export const formatHours = (value: number): string => value.toFixed(2);
export const formatVAT = (vat: number) => `${Math.round(vat * 100)}%`;
export const formatCurrency = (
  value: number,
  down: boolean = false
): string => {
  value = down ? Math.floor(value * 100) / 100 : Math.ceil(value * 100) / 100;
  let newValue = parseFloat(`${value}`).toLocaleString("nl-nl");

  if (newValue.indexOf(",") === -1) {
    newValue += ",00";
  } else if (newValue.substring(newValue.indexOf(",")).length === 2) {
    newValue += "0";
  }

  return newValue;
};

export const capitalize = (str: string): string =>
  `${str.substring(0, 1).toUpperCase()}${str.substring(1)}`;
export const upper = (str: string): string => str.toUpperCase();
export const lower = (str: string): string => str.toLowerCase();
