import { DocumentSelection } from "../types";

export const cleanHTML = (parentNode: HTMLElement): HTMLElement => {
  const passes = 5;

  new Array(passes).fill(1).map(() => {
    const children = [...parentNode.childNodes];
    for (let i = 0; i < children.length; i++) {
      parseElement(parentNode, parentNode, children[i]);
    }
  });

  // for (let node of parentNode.childNodes) {
  //   parseElement(parentNode, parentNode, node);
  // }

  return parentNode;
};

const parseElement = (
  origin: HTMLElement,
  parent: HTMLElement,
  node: Node
): Node | null => {
  const el = getElement(node);

  if (node.nodeType === Node.TEXT_NODE && node.textContent?.trim() === "") {
    parent.removeChild(node);

    return null;
  }

  // no empty nodes, apart from images
  if (el && isEmpty(el) && !isImage(el)) {
    parent.removeChild(el);

    return null;
  }

  // // block nodes should be a child of parent
  if (el && isBlockElement(el) && parent !== origin) {
    origin.insertBefore(el, parent);
    return null;
  }

  // text should occur only in paragraphs, h4, or li, pre
  else if (node && node.nodeType === Node.TEXT_NODE) {
    if (
      parent &&
      !isBlockElement(parent) &&
      !isListItem(parent) &&
      !isInlineElement(parent)
    ) {
      const p = document.createElement("p");

      origin.insertBefore(p, parent === origin ? node : parent);
      p.appendChild(node);
    }
  }

  // // recursively clean
  if (el) {
    for (let node of el.childNodes) {
      parseElement(origin, el, node);
    }
  }

  return el;
};

export const getElement = (el: Node) =>
  el.nodeType === Node.ELEMENT_NODE ? (el as HTMLElement) : null;

export const getParentElement = (el: HTMLElement | Node): HTMLElement => {
  if (el.nodeType === Node.ELEMENT_NODE) {
    return el as HTMLElement;
  }
  return getParentElement(el.parentNode!);
};

export const getBlockElement = (el: HTMLElement | Node): HTMLElement => {
  if (el.nodeType === Node.ELEMENT_NODE && isBlockElement(el as HTMLElement)) {
    return el as HTMLElement;
  }
  return getBlockElement(el.parentElement!);
};

export const isEmpty = (node: Node): boolean =>
  !node.firstChild ||
  (node.textContent?.trim() === "" &&
    [...node.childNodes].every((child) => isEmpty(child)));

const isBlockElement = (el: HTMLElement): boolean =>
  ["p", "pre", "h4", "ul", "ol", "img"].includes(el.tagName.toLowerCase());

const isInlineElement = (el: HTMLElement): boolean =>
  ["b", "i", "a", "code"].includes(el.tagName.toLowerCase());

export const isHeader = (node: Node): boolean => isElementOfType(node, "h4");
export const isParagraph = (node: Node): boolean => isElementOfType(node, "p");
export const isOrderedList = (node: Node): boolean =>
  isElementOfType(node, "ol");
export const isUnorderedList = (node: Node): boolean =>
  isElementOfType(node, "ul");
export const isList = (node: Node): boolean =>
  isOrderedList(node) || isUnorderedList(node);
export const isImage = (node: Node): boolean => isElementOfType(node, "img");
export const isListItem = (node: Node): boolean => isElementOfType(node, "li");
export const isPre = (node: Node): boolean => isElementOfType(node, "pre");
export const isBold = (node: Node): boolean => isElementOfType(node, "b");
export const isItalic = (node: Node): boolean => isElementOfType(node, "i");
export const isLink = (node: Node): boolean => isElementOfType(node, "a");
export const isCode = (node: Node): boolean => isElementOfType(node, "code");

const isElementOfType = (node: Node, type: string): boolean => {
  const el = getElement(node);
  return !!el && el.tagName.toLowerCase() === type;
};

export const isText = (node: Node): boolean => node.nodeType === Node.TEXT_NODE;

export const insertNode = (
  path: string,
  attributes: Record<string, string> = {},
  collapse: boolean = false
): HTMLElement => {
  const structure = path.split("/");
  let rootNode = null;
  let node = null;

  for (let index = 0; index < structure.length; index++) {
    const tag = structure[index];

    const newNode = document.createElement(tag);
    if (!rootNode) {
      rootNode = newNode;
    }

    if (node) {
      node.appendChild(newNode);
    }
    node = newNode;
  }

  // add attributes
  for (let key in attributes) {
    rootNode?.setAttribute(key, attributes[key]);
  }

  const selection = getSelection();

  if (selection) {
    console.log(selection.anchorNode, selection.focusNode, rootNode);
  }

  // insert the contents in the lowest childNode
  if (
    rootNode &&
    node &&
    selection &&
    selection.anchorNode &&
    selection.focusNode
  ) {
    const range = document.createRange();
    range.setStart(selection?.anchorNode, selection?.anchorOffset);
    range.setEnd(selection?.focusNode, selection?.focusOffset);
    if (collapse) {
      range.setEnd(selection?.anchorNode, selection?.anchorOffset);
    } else {
      node.appendChild(range.extractContents());
    }
    range.insertNode(rootNode);
  }

  return rootNode!;
};

export const insertImage = (_: DocumentSelection) => {
  return insertNode(
    "img",
    { src: "", alt: "", align: "left", width: "" },
    true
  );
};
export const insertQuote = (_: DocumentSelection) => {
  insertNode("blockquote");
};
export const insertCode = (_: DocumentSelection) => {
  insertNode("pre", { type: "javascript" });
};
export const insertInlineCode = (_: DocumentSelection) => {
  insertNode("code");
};

export const insertOrderedList = (selection: DocumentSelection) => {
  const element = selection.startBlockElement;

  if (element && isParagraph(element)) {
    const ol = document.createElement("ol");

    for (let index = 0; index < selection.nodeRange.length; index++) {
      const child = selection.nodeRange[index];

      const li = document.createElement("li");
      ol.appendChild(li);

      for (var node of child.childNodes) {
        li.appendChild(node);
      }
    }

    element.replaceWith(ol);
  } else if (element && isOrderedList(element)) {
    undoList(element);
  } else if (element && isUnorderedList(element)) {
    changeList(element, "ol");
  }
};

export const insertUnorderedList = (selection: DocumentSelection) => {
  const element = selection.startBlockElement;

  if (element && isParagraph(element)) {
    const ol = document.createElement("ul");

    for (let index = 0; index < selection.nodeRange.length; index++) {
      const child = selection.nodeRange[index];

      const li = document.createElement("li");
      ol.appendChild(li);

      for (var node of child.childNodes) {
        li.appendChild(node);
      }
    }

    element.replaceWith(ol);
  } else if (element && isUnorderedList(element)) {
    undoList(element);
  } else if (element && isOrderedList(element)) {
    changeList(element, "ul");
  }
};

export const undoList = (element: HTMLElement) => {
  while (element.childNodes.length > 0) {
    const child = element.childNodes[element.childNodes.length - 1];
    const p = document.createElement("p");
    p.innerHTML = (child as HTMLElement).innerHTML;
    element.removeChild(child);

    if (element.nextElementSibling) {
      element.parentNode!.insertBefore(p, element.nextElementSibling);
    } else {
      element.parentNode!.appendChild(p);
    }
  }
  element.parentNode?.removeChild(element);
};

export const changeList = (element: HTMLElement, tagName: string) => {
  const newList = document.createElement(tagName);

  while (element.childNodes.length > 0) {
    const child = element.childNodes[element.childNodes.length - 1];
    newList.appendChild(child);
  }

  element.replaceWith(newList);
};
