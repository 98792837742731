import {
  BaseService,
  Status,
  DataResponse,
  MessageData,
  MessageResponse
} from "@/core/services/base.service";
import { Note } from "@/models/note";

class NoteService extends BaseService {
  private url: string = "/api/note";
  private cached: Note[] = [];

  constructor() {
    super();
  }

  public new(): Note {
    return new Note({
      id: -1,
      date_created: new Date().toISOString(),
      date_modified: new Date().toISOString(),
      title: "",
      description: "",
      shortcut_keys: [],
      urls: [],
      chapters: []
    });
  }

  public async getList(): Promise<DataResponse<Note[]>> {
    if (this.hasCache()) {
      return {
        status: true,
        data: this.getCache()
      };
    }
    const res = await this.$http.get<Note[]>(`${this.url}`);
    this.setCache(res.data.map((d) => new Note(d)));
    return {
      status: res.status === Status.Ok,
      data: this.getCache()
    };
  }

  public async get(id: number): Promise<DataResponse<Note>> {
    const res = await this.$http.get<Note>(`${this.url}/${id}`);
    return {
      status: res.status === Status.Ok,
      data: new Note(res.data)
    };
  }

  public async post(note: Note): Promise<DataResponse<Note>> {
    const res = await this.$http.post<Note>(this.url, {
      ...note,
      date_created: new Date().toISOString()
    });
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      data: new Note(res.data)
    };
  }

  public async put(note: Note): Promise<DataResponse<Note>> {
    const res = await this.$http.put<Note>(`${this.url}/${note.id}`, note);
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      data: new Note(res.data)
    };
  }

  public async delete(id: number): Promise<MessageResponse> {
    const res = await this.$http.delete<MessageData>(`${this.url}/${id}`);
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      message: res.data.message
    };
  }

  private getCache(): Note[] {
    return this.cached;
  }

  private setCache(list: Note[]): void {
    this.cached = list;
  }

  private hasCache(): boolean {
    return this.cached.length !== 0;
  }

  private clearCache(): void {
    this.setCache([]);
  }
}

export default new NoteService();
