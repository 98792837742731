import { AttributeConfig, Config } from "./types";

export const MarkdownEditorConfig: Config = {
  elements: {
    p: "Paragraph",
    ul: "Unordered list",
    ol: "Ordered list",
    li: "List item",
    pre: "Code block",
    b: "Bold",
    i: "Italic",
    s: "Strikethrough",
    a: "Link",
    code: "Code",
    img: "Image"
  },
  attributes: {
    href: {
      name: "Url",
      placeholder: "https://",
      hasLinkLookup: true
    },
    target: {
      name: "Target",
      options: [
        { label: "New window", value: "_blank" },
        { label: "Current window", value: "_top" },
        { label: "Current frame", value: "_self" },
        { label: "Parent frame", value: "_parent" }
      ]
    },
    alt: {
      name: "Alt Text"
    },
    src: {
      name: "Image",
      hasImageLookup: true
    },
    align: {
      name: "Alignment",
      options: [
        { label: "Left", value: "left" },
        { label: "Center", value: "center" },
        { label: "Right", value: "right" }
      ]
    },
    type: {
      name: "Type",
      options: [
        { label: "Javascript", value: "javascript" },
        { label: "Golang", value: "go" },
        { label: "Bash", value: "bash" }
      ]
    }
  }
};

export const getAttributeConfig = (attr: string): AttributeConfig => {
  return MarkdownEditorConfig.attributes[attr] || {};
};

export const getElementName = (tag: string): string => {
  return MarkdownEditorConfig.elements[tag] || tag;
};

export const getLabel = (attr: string): string => {
  return getAttributeConfig(attr)?.name || attr;
};

export const getPlaceholder = (attr: string): string => {
  return getAttributeConfig(attr)?.placeholder || "";
};
