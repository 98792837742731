import {
  BaseService,
  Status,
  DataResponse,
  MessageData,
  MessageResponse
} from "@/core/services/base.service";
import { CalendarData } from "@/models/calendar";
import { Learning } from "@/models/learning";
import { format } from "date-fns";

class LearningService extends BaseService {
  private url: string = "/api/learn";
  private cached: Learning[] = [];

  constructor() {
    super();
  }

  public new(): Learning {
    return new Learning({
      id: -1,
      date: format(new Date(), "yyyy-MM-dd"),
      learned: false,
      activity: ""
    });
  }

  public async getCalendar(): Promise<DataResponse<CalendarData>> {
    const res = await this.$http.get<CalendarData>(`/api/calendar`);

    return {
      status: res.status === Status.Ok,
      data: res.data
    };
  }

  public async getList(): Promise<DataResponse<Learning[]>> {
    if (this.hasCache()) {
      return {
        status: true,
        data: this.getCache()
      };
    }
    const res = await this.$http.get<Learning[]>(`${this.url}`);
    this.setCache(
      res.data
        .map((d) => new Learning(d))
        .sort((a, b) => Date.parse(b.date) - Date.parse(a.date))
    );
    return {
      status: res.status === Status.Ok,
      data: this.getCache()
    };
  }

  public async get(id: number): Promise<DataResponse<Learning>> {
    const res = await this.$http.get<Learning>(`${this.url}/${id}`);
    return {
      status: res.status === Status.Ok,
      data: new Learning(res.data)
    };
  }

  public async post(learning: Learning): Promise<DataResponse<Learning>> {
    const res = await this.$http.post<Learning>(this.url, {
      ...learning,
      date: new Date(Date.parse(learning.date)).toISOString()
    });
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      data: new Learning(res.data)
    };
  }

  public async put(learning: Learning): Promise<DataResponse<Learning>> {
    const res = await this.$http.put<Learning>(
      `${this.url}/${learning.id}`,
      learning
    );
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      data: new Learning(res.data)
    };
  }

  public async delete(id: number): Promise<MessageResponse> {
    const res = await this.$http.delete<MessageData>(`${this.url}/${id}`);
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      message: res.data.message
    };
  }

  private getCache(): Learning[] {
    return this.cached;
  }

  private setCache(list: Learning[]): void {
    this.cached = list;
  }

  private hasCache(): boolean {
    return this.cached.length !== 0;
  }

  private clearCache(): void {
    this.setCache([]);
  }
}

export default new LearningService();
