<script lang="ts" setup>
import { PropType, computed, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store";
import { Shortcut } from "@/models/shortcut";
import { useLoading } from "../common/loading.composable";
import FieldChoice, { Item } from "../common/FieldChoice.vue";

const store = useStore();

const props = defineProps({
  shortcuts: {
    type: Object as PropType<Shortcut[]>
  }
});

const shortcutOptions = computed<Item[]>(() =>
  (props.shortcuts || []).map((s) => {
    return {
      label: s.name,
      value: s
    };
  })
);

const choice = reactive({ value: null });

const { loading, setLoading } = useLoading();

onMounted(() => {
  store.dispatch(Actions.GetApplications);
  store.dispatch(Actions.GetShortcuts);

  setLoading(false);
});

const emit = defineEmits(["choose", "close"]);

const choose = () => {
  if (choice.value) {
    emit("choose", choice.value);
  }
};
</script>
<template>
  <form v-if="shortcutOptions.length">
    <h2>Choose shortcut</h2>
    <FieldChoice
      label="Shortcut"
      v-model="choice.value"
      :items="shortcutOptions"
    ></FieldChoice>
    <div class="buttons">
      <button class="confirm" type="button" :disabled="loading" @click="choose">
        <span class="icon icon-pencil"></span>
        <span class="text">Add</span>
      </button>
    </div>
  </form>
</template>
