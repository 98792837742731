<script lang="ts" setup>
import { onMounted, PropType, ref, watch } from 'vue';
import { User } from '@/models/user';
import Field from '@/components/common/Field.vue';
import AddressFields from '@/components/common/AddressFields.vue';

const { modelValue, validation, loading } = defineProps({
  modelValue: { type: Object as PropType<User>, required: true },
  validation: { type: Object, required: true },
  loading: { type: Boolean, required: true },
});

const emit = defineEmits(['update:modelValue']);

const user = ref<User>(modelValue);

onMounted(() => {
  user.value = modelValue;
});

watch(user, (v) => emit('update:modelValue', v));
</script>
<template>
  <div>
    <Field
      label="First name"
      :validation="validation.first_name"
      v-model="user.first_name"
      :disabled="loading"
    ></Field>
    <Field
      label="Last name"
      :validation="validation.last_name"
      v-model="user.last_name"
      :disabled="loading"
    ></Field>
    <Field
      label="Account"
      :validation="validation.account"
      v-model="user.account"
      :disabled="loading"
    ></Field>
    <Field
      label="Phone"
      :validation="validation.phone"
      v-model="user.phone"
      :disabled="loading"
      styling="half"
    ></Field>
    <AddressFields
      v-model="user.address"
      :validation="validation.address"
      :loading="loading"
    ></AddressFields>
  </div>
</template>
