<script lang="ts" setup>
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store";
import NoteForm from "@/components/note/NoteForm.vue";

const store = useStore();
const route = useRoute();

onMounted(() => {
  if (route.params.id) {
    store.dispatch(Actions.GetNote, route.params.id);
  }
});
</script>
<template><NoteForm v-if="store.state.note"></NoteForm></template>
