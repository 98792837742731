import {
  BaseService,
  Status,
  DataResponse,
  MessageResponse,
  MessageData
} from "@/core/services/base.service";
import { Auth, LoginData } from "@/models/auth";

class AuthService extends BaseService {
  private url: string = "/api/auth";

  constructor() {
    super();
  }

  public async get(): Promise<DataResponse<Auth | null>> {
    const res = await this.$http.get<Auth>(this.url);
    return {
      status: res && res.status === Status.Ok,
      data: res ? new Auth(res.data) : null
    };
  }

  public async login(auth: LoginData): Promise<DataResponse<Auth | null>> {
    const res = await this.$http.post<Auth>(`${this.url}/login`, auth);

    return {
      status: res && res.status === Status.Ok,
      data: res ? new Auth(res.data) : null
    };
  }

  public async logout(): Promise<MessageResponse> {
    const res = await this.$http.get<MessageData>(`${this.url}/logout`);
    return {
      status: res.status === Status.Ok,
      message: res.data.message
    };
  }
}

export default new AuthService();
