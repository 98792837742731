import { Note } from "@/models/note";
import mitt from "mitt";

export enum Event {
  ShortcutCreated = "ShortcutCreated",
  NoteCreated = "NoteCreated"
}

export type Events = {
  [Event.ShortcutCreated]: null;
  [Event.NoteCreated]: Note;
};

const eventStore = mitt<Events>();

export const useEvents = () => {
  return eventStore;
};
