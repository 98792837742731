import { createStore } from "vuex";
// models
import { LoginData, Auth } from "@/models/auth";
import { User } from "@/models/user";
import { Learning } from "@/models/learning";
import { Blog } from "./models/blog";
import { Note, getNoteData } from "./models/note";
import { Application } from "./models/application";
import { Lesson } from "./models/lesson";
import { Result } from "./models/results.model";
import {
  getNotificationId,
  getNotificationTimeout,
  Notification
} from "@/models/notification";
// services
import authService from "@/services/auth.service";
import userService from "@/services/user.service";
import learningService from "@/services/learning.service";
import blogService from "@/services/blog.service";
import noteService from "@/services/note.service";
import lessonService from "@/services/lesson.service";
import applicationService from "@/services/application.service";
import shortcutService from "@/services/shortcut.service";
import searchService from "./services/search.service";
// modules
import { drawerStore } from "./stores/drawer.store";
import { modalStore } from "./stores/modal.store";
import { Shortcut } from "./models/shortcut";
import { CalendarData } from "./models/calendar";

export interface State {
  auth: Auth | null;
  calendar: CalendarData | null;
  user: User | null;
  profile: User | null;
  filter: string;
  learning: Learning | null;
  learnings: Learning[];
  blog: Blog | null;
  blogs: Blog[];
  note: Note | null;
  notes: Note[];
  lesson: Lesson | null;
  lessons: Lesson[];
  shortcut: Shortcut | null;
  shortcuts: Shortcut[];
  application: Application | null;
  applications: Application[];
  notifications: Notification[];
  results: Result<any>[];
}

export enum Mutations {
  SetAuth = "SetAuth",
  SetCalendar = "SetCalendar",
  SetUser = "SetUser",
  SetProfile = "SetProfile",
  UpdateUser = "UpdateUser",
  UpdateFilter = "UpdateFilter",
  SetLearnings = "SetLearnings",
  SetLearning = "SetLearning",
  UpdateLearning = "UpdateLearning",
  SetBlogs = "SetBlogs",
  SetBlog = "SetBlog",
  UpdateBlog = "UpdateBlog",
  SetNotes = "SetNotes",
  SetNote = "SetNote",
  UpdateNote = "UpdateNote",
  SetLessons = "SetLessons",
  SetLesson = "SetLesson",
  UpdateLesson = "UpdateLesson",
  SetApplications = "SetApplications",
  SetApplication = "SetApplication",
  UpdateApplication = "UpdateApplication",
  SetShortcuts = "SetShortcuts",
  SetShortcut = "SetShortcut",
  UpdateShortcut = "UpdateShortcut",
  ShowNotification = "ShowNotification",
  DismissNotification = "DismissNotification",
  SetSearchResults = "SetSearchResults",
  ResetSearchResults = "ResetSearchResults"
}

export enum Actions {
  Auth = "Auth",
  Login = "Login",
  Logout = "Logout",
  GetCalendar = "GetCalendar",
  GetProfile = "GetProfile",
  UpdateProfile = "UpdateProfile",
  GetUser = "GetUser",
  GetLearnings = "GetLearnings",
  NewLearning = "NewLearning",
  StoreLearning = "StoreLearning",
  GetLearning = "GetLearning",
  RemoveLearning = "RemoveLearning",
  GetBlogs = "GetBlogs",
  NewBlog = "NewBlog",
  StoreBlog = "StoreBlog",
  GetBlog = "GetBlog",
  RemoveBlog = "RemoveBlog",
  GetNotes = "GetNotes",
  NewNote = "NewNote",
  StoreNote = "StoreNote",
  GetNote = "GetNote",
  RemoveNote = "RemoveNote",
  GetLessons = "GetLessons",
  NewLesson = "NewLesson",
  StoreLesson = "StoreLesson",
  GetLesson = "GetLesson",
  RemoveLesson = "RemoveLesson",
  GetApplications = "GetApplications",
  NewApplication = "NewApplication",
  StoreApplication = "StoreApplication",
  GetApplication = "GetApplication",
  RemoveApplication = "RemoveApplication",
  GetShortcuts = "GetShortcuts",
  NewShortcut = "NewShortcut",
  StoreShortcut = "StoreShortcut",
  GetShortcut = "GetShortcut",
  RemoveShortcut = "RemoveShortcut",
  ShowNotification = "ShowNotification",
  Search = "Search"
}

export const store = createStore<State>({
  modules: {
    ds: drawerStore,
    ms: modalStore
  },
  state: {
    auth: null,
    calendar: null,
    user: null,
    profile: null,
    filter: "",
    learning: null,
    learnings: [],
    blog: null,
    blogs: [],
    note: null,
    notes: [],
    lesson: null,
    lessons: [],
    shortcut: null,
    shortcuts: [],
    application: null,
    applications: [],
    notifications: [],
    results: []
  },
  mutations: {
    [Mutations.SetAuth]: (state: State, auth: Auth) => {
      state.auth = auth;
    },
    [Mutations.SetCalendar]: (state: State, calendar) => {
      state.calendar = calendar;
    },
    [Mutations.SetUser]: (state: State, user) => {
      state.user = user;
    },
    [Mutations.SetProfile]: (state: State, profile: User) => {
      state.profile = profile;
    },
    [Mutations.UpdateUser]: (state: State, user) => {
      state.user = Object.assign(state.user || {}, user);
    },
    [Mutations.SetLearning]: (state: State, learning: Learning) => {
      state.learning = learning;
    },
    [Mutations.SetLearnings]: (state: State, learnings: Learning[]) => {
      state.learnings = learnings;
    },
    [Mutations.SetBlog]: (state: State, blog: Blog) => {
      state.blog = blog;
    },
    [Mutations.SetBlogs]: (state: State, blogs: Blog[]) => {
      state.blogs = blogs;
    },
    [Mutations.SetNote]: (state: State, note: Note) => {
      state.note = note;
    },
    [Mutations.SetNotes]: (state: State, notes: Note[]) => {
      state.notes = notes;
    },
    [Mutations.SetLesson]: (state: State, lesson: Lesson) => {
      state.lesson = lesson;
    },
    [Mutations.SetLessons]: (state: State, lessons: Lesson[]) => {
      state.lessons = lessons;
    },
    [Mutations.SetShortcut]: (state: State, shortcut: Shortcut) => {
      state.shortcut = shortcut;
    },
    [Mutations.SetShortcuts]: (state: State, shortcuts: Shortcut[]) => {
      state.shortcuts = shortcuts;
    },
    [Mutations.SetApplication]: (state: State, application: Application) => {
      state.application = application;
    },
    [Mutations.SetApplications]: (
      state: State,
      applications: Application[]
    ) => {
      console.log("hi there", applications);
      state.applications = applications;
    },
    [Mutations.UpdateFilter]: (state: State, filter: string) => {
      state.filter = filter;
    },
    [Mutations.ShowNotification]: (
      state: State,
      notification: Notification
    ) => {
      state.notifications = [...state.notifications, notification];
    },
    [Mutations.DismissNotification]: (state: State, id: Number) => {
      state.notifications = [...state.notifications.filter((n) => n.id !== id)];
    },
    [Mutations.SetSearchResults]: (state: State, results: Result<any>[]) => {
      state.results = results;
    },
    [Mutations.ResetSearchResults]: (state: State) => {
      state.results = [];
    }
  },
  actions: {
    async [Actions.Login](
      { commit, dispatch },
      data: LoginData
    ): Promise<Auth | null> {
      const res = await authService.login(data);
      if (res && res.status) {
        commit(Mutations.SetAuth, res.data);
        await dispatch(Actions.GetProfile);
        dispatch(Actions.ShowNotification, {
          message: "Login successful",
          type: "info"
        });
        return dispatch(Actions.Auth);
      } else {
        return null;
      }
    },
    async [Actions.Logout]({ commit }) {
      await authService.logout();
      commit(Mutations.SetAuth, null);
    },
    async [Actions.Auth]({ commit, state }): Promise<Auth | null> {
      if (state.auth && !state.auth.isExpired()) {
        return state.auth;
      }
      const res = await authService.get();
      commit(Mutations.SetAuth, res.data);
      return state.auth;
    },
    async [Actions.GetCalendar]({ commit }): Promise<CalendarData> {
      const res = await learningService.getCalendar();
      commit(Mutations.SetCalendar, res.data);
      return res.data;
    },
    async [Actions.GetUser]({ commit }, id: number): Promise<User> {
      const res = await userService.get(id);
      commit(Mutations.SetUser, res.data);
      return res.data;
    },
    async [Actions.GetProfile]({ commit, state }): Promise<User | undefined> {
      if (state.profile) {
        return state.profile;
      }
      const res = await userService.profile();
      if (res.data) {
        commit(Mutations.SetProfile, res.data);
      }
      return res.data;
    },
    async [Actions.UpdateProfile]({ commit }, profile) {
      const res = await userService.put(profile);
      if (res.status) {
        commit(Mutations.SetProfile, res.data);
      }
    },
    async [Actions.GetLearnings]({ commit }): Promise<Learning[]> {
      const res = await learningService.getList();
      commit(Mutations.SetLearnings, res.data);
      return res.data;
    },
    async [Actions.NewLearning]({ commit }) {
      const learning = learningService.new();
      commit(Mutations.SetLearning, learning);
    },
    async [Actions.StoreLearning]({ dispatch, commit }, learning) {
      dispatch(Actions.ShowNotification, {
        message: "Saving learning",
        type: "info"
      });

      let p: Learning;
      if (!learning.id || learning.id === -1) {
        const d = await learningService.post(learning);
        p = d.data;
      } else {
        const d = await learningService.put(learning);
        p = d.data;
      }
      commit(Mutations.SetLearning, p);
      dispatch(Actions.ShowNotification, {
        message: "Learning saved",
        type: "confirm"
      });
      return dispatch(Actions.GetLearnings);
    },
    async [Actions.GetLearning](
      { commit },
      id: number
    ): Promise<Learning | undefined> {
      const res = await learningService.get(id);
      commit(Mutations.SetLearning, res.data);
      return res.data;
    },
    async [Actions.RemoveLearning]({ dispatch }, id: number) {
      await learningService.delete(id);
      dispatch(Actions.GetLearnings);
    },
    async [Actions.GetBlogs]({ commit }): Promise<Blog[]> {
      const res = await blogService.getList();
      commit(Mutations.SetBlogs, res.data);
      return res.data;
    },
    async [Actions.NewBlog]({ commit }) {
      const blog = blogService.new();
      commit(Mutations.SetBlog, blog);
    },
    async [Actions.StoreBlog]({ dispatch, commit }, blog) {
      dispatch(Actions.ShowNotification, {
        message: "Saving blog",
        type: "info"
      });

      let p: Blog;
      if (!blog.id || blog.id === -1) {
        const d = await blogService.post(blog);
        p = d.data;
      } else {
        const d = await blogService.put(blog);
        p = d.data;
      }
      commit(Mutations.SetBlog, p);
      dispatch(Actions.ShowNotification, {
        message: "Blog saved",
        type: "confirm"
      });
      return dispatch(Actions.GetBlogs);
    },
    async [Actions.GetBlog]({ commit }, id: number): Promise<Blog | undefined> {
      const res = await blogService.get(id);
      commit(Mutations.SetBlog, res.data);
      return res.data;
    },
    async [Actions.RemoveBlog]({ dispatch }, id: number) {
      await blogService.delete(id);
      dispatch(Actions.GetBlogs);
    },
    async [Actions.GetNotes]({ commit }): Promise<Note[]> {
      const res = await noteService.getList();
      commit(Mutations.SetNotes, res.data);
      return res.data;
    },
    async [Actions.NewNote]({ commit }) {
      const note = noteService.new();
      commit(Mutations.SetNote, note);
    },
    async [Actions.StoreNote]({ dispatch, commit }, note) {
      dispatch(Actions.ShowNotification, {
        message: "Saving note",
        type: "info"
      });

      let p: Note;
      if (!note.id || note.id === -1) {
        const d = await noteService.post(note);
        p = d.data;
      } else {
        const d = await noteService.put(note);
        p = d.data;
      }
      commit(Mutations.SetNote, p);
      dispatch(Actions.ShowNotification, {
        message: "Note saved",
        type: "confirm"
      });
      return dispatch(Actions.GetNotes);
    },
    async [Actions.GetNote]({ commit }, id: number): Promise<Note | undefined> {
      const res = await noteService.get(id);
      commit(Mutations.SetNote, res.data);
      return res.data;
    },
    async [Actions.RemoveNote]({ dispatch }, id: number) {
      await noteService.delete(id);
      dispatch(Actions.GetNotes);
    },
    async [Actions.GetLessons]({ commit }): Promise<Lesson[]> {
      const res = await lessonService.getList();
      commit(Mutations.SetLessons, res.data);
      return res.data;
    },
    async [Actions.NewLesson]({ commit }) {
      const lesson = lessonService.new();
      commit(Mutations.SetLesson, lesson);
    },
    async [Actions.StoreLesson]({ dispatch, commit }, lesson) {
      dispatch(Actions.ShowNotification, {
        message: "Saving lesson",
        type: "info"
      });

      let p: Lesson;
      if (!lesson.id || lesson.id === -1) {
        const d = await lessonService.post(lesson);
        p = d.data;
      } else {
        const d = await lessonService.put(lesson);
        p = d.data;
      }
      commit(Mutations.SetLesson, p);
      dispatch(Actions.ShowNotification, {
        message: "Lesson saved",
        type: "confirm"
      });
      return dispatch(Actions.GetLessons);
    },
    async [Actions.GetLesson](
      { commit },
      id: number
    ): Promise<Lesson | undefined> {
      const res = await lessonService.get(id);
      commit(Mutations.SetLesson, res.data);
      return res.data;
    },
    async [Actions.RemoveLesson]({ dispatch }, id: number) {
      await lessonService.delete(id);
      dispatch(Actions.GetLessons);
    },
    async [Actions.GetApplications]({ commit }): Promise<Application[]> {
      const res = await applicationService.getList();
      commit(Mutations.SetApplications, res.data);
      return res.data;
    },
    async [Actions.NewApplication]({ commit }) {
      const application = applicationService.new();
      commit(Mutations.SetApplication, application);
    },
    async [Actions.StoreApplication]({ dispatch, commit }, application) {
      dispatch(Actions.ShowNotification, {
        message: "Saving application",
        type: "info"
      });

      let p: Application;
      if (!application.id || application.id === -1) {
        const d = await applicationService.post(application);
        p = d.data;
      } else {
        const d = await applicationService.put(application);
        p = d.data;
      }
      commit(Mutations.SetApplication, p);
      dispatch(Actions.ShowNotification, {
        message: "Application saved",
        type: "confirm"
      });
      return dispatch(Actions.GetApplications);
    },
    async [Actions.GetApplication](
      { commit },
      id: number
    ): Promise<Application | undefined> {
      const res = await applicationService.get(id);
      commit(Mutations.SetApplication, res.data);
      return res.data;
    },
    async [Actions.RemoveApplication]({ dispatch }, id: number) {
      await applicationService.delete(id);
      dispatch(Actions.GetApplications);
    },
    async [Actions.GetShortcuts]({ commit }): Promise<Shortcut[]> {
      const res = await shortcutService.getList();
      commit(Mutations.SetShortcuts, res.data);
      return res.data;
    },
    async [Actions.NewShortcut]({ commit }) {
      const shortcut = shortcutService.new();
      commit(Mutations.SetShortcut, shortcut);
    },
    async [Actions.StoreShortcut]({ dispatch, commit }, shortcut) {
      dispatch(Actions.ShowNotification, {
        message: "Saving shortcut",
        type: "info"
      });

      let p: Shortcut;
      if (!shortcut.id || shortcut.id === -1) {
        const d = await shortcutService.post(shortcut);
        p = d.data;
      } else {
        const d = await shortcutService.put(shortcut);
        p = d.data;
      }
      commit(Mutations.SetShortcut, p);
      dispatch(Actions.ShowNotification, {
        message: "Shortcut saved",
        type: "confirm"
      });
      return dispatch(Actions.GetShortcuts);
    },
    async [Actions.GetShortcut](
      { commit },
      id: number
    ): Promise<Shortcut | undefined> {
      const res = await shortcutService.get(id);
      commit(Mutations.SetShortcut, res.data);
      return res.data;
    },
    async [Actions.RemoveShortcut]({ dispatch }, id: number) {
      await shortcutService.delete(id);
      dispatch(Actions.GetShortcuts);
    },
    async [Actions.ShowNotification](
      { commit, state },
      notification: Pick<Notification, "message" | "type">
    ): Promise<Notification> {
      const id = getNotificationId(state.notifications);
      const timeout = getNotificationTimeout(notification.type);
      const n = { ...notification, id };

      commit(Mutations.ShowNotification, n);

      if (timeout) {
        setTimeout(() => commit(Mutations.DismissNotification, id), timeout);
      }

      return n;
    },
    async [Actions.Search](
      { commit, state },
      value: string
    ): Promise<Result<any>[]> {
      const res = await searchService.search(value);

      commit(Mutations.SetSearchResults, res.data);

      return res.data;
    }
  },
  getters: {
    filteredNotes: (state: State) => {
      return state.notes.filter((note) =>
        getNoteData(note).some((v: string) => v.includes(state.filter))
      );
    },
    filteredShortcuts: (state: State) => {
      return state.shortcuts.filter((shortcut) =>
        [shortcut.name.toLowerCase(), shortcut.description.toLowerCase()].some(
          (v: string) => v.includes(state.filter)
        )
      );
    },
    filteredApplications: (state: State) => {
      return state.applications.filter((app) => {
        const noteData = (app.features || [])
          .reduce((acc: Note[], id: number) => {
            const note = state.notes.find((n) => n.id === id);
            return note ? [...acc, note] : acc;
          }, [])
          .reduce(
            (acc: string[], note: Note) => [...acc, ...getNoteData(note)],
            []
          );

        return [
          (app.name || "").toLowerCase(),
          (app.description || "").toLowerCase(),
          ...noteData
        ]
          .filter((v) => !!v)
          .some((v: string) => v.includes(state.filter));
      });
    }
  }
});
