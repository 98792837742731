<script lang="ts" setup>
import { PropType, computed, onMounted, reactive, watch } from "vue";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { Actions, Mutations } from "@/store";
import { Shortcut, shortcutValidations } from "@/models/shortcut";
import Loading from "@/components/common/Loading.vue";
import Field from "@/components/common/Field.vue";
import { useLoading } from "../common/loading.composable";
import FieldLong from "../common/FieldLong.vue";
import FieldChoice, { Item } from "../common/FieldChoice.vue";
import { Application } from "@/models/application";
import { DrawerActions } from "@/stores/drawer.store";
import { useEvents, Event } from "@/core/services/events.composable";
import { router } from "@/router";
import ShortcutKey from "./ShortcutKey.vue";
import ShortcutAddForm from "./ShortcutAddForm.vue";

const store = useStore();
const events = useEvents();

const props = defineProps({
  data: {
    type: Object as PropType<{ application: number; shortcuts: Shortcut[] }>
  }
});

const s = computed<Shortcut>(() => store.state.shortcut);

watch(
  () => store.state.shortcut,
  (v) => {
    Object.assign(shortcut, {
      ...v
    });
  }
);

const relatedShortcuts = computed<Shortcut[]>(() => {
  return store.state.shortcuts.filter(
    (s: Shortcut) =>
      s.application_id === shortcut.application_id &&
      s.shortcut_key.toLowerCase() === shortcut.shortcut_key.toLowerCase() &&
      !!s.ctrl_key === !!shortcut.ctrl_key &&
      !!s.alt_key === !!shortcut.alt_key &&
      !!s.shift_key === !!shortcut.shift_key &&
      !!s.command_key === !!shortcut.command_key
  );
});

const shortcut = reactive<Shortcut>({
  ...store.state.shortcut
});
const isNew = computed<boolean>(() => shortcut.id === -1);
const typeOptions: Item[] = [
  {
    label: "Shortcut",
    value: "shortcut"
  },
  {
    label: "Modifier",
    value: "modifier"
  }
];
const applicationOptions = computed<Item[]>(() => [
  { label: "None", value: undefined },
  ...store.state.applications.map((app: Application) => ({
    label: app.name,
    value: app.id
  }))
]);
const modeOptions = computed<Item[]>(() => {
  const application = store.state.applications.find(
    (app: Application) => app.id === shortcut.application_id
  );
  const modes = application?.modes || [];

  return [
    { label: "None", value: undefined },
    ...modes.map((mode: string) => ({
      label: mode,
      value: mode
    }))
  ];
});

const { loading, setLoading } = useLoading();

onMounted(() => {
  store.dispatch(Actions.GetApplications);
  store.dispatch(Actions.GetShortcuts);

  if (s.value?.id !== -1 && s.value) {
    Object.assign(shortcut, { ...s.value });
  } else {
    store.dispatch(Actions.NewShortcut);
  }
  if (props.data?.application || store.state.shortcut) {
    const application_id =
      props.data?.application || store.state.shortcut.application_id;

    Object.assign(shortcut, { ...store.state.shortcut, application_id });
  }
});

const $v = useVuelidate(shortcutValidations, { shortcut });
const emit = defineEmits(["close"]);

const submit = async () => {
  $v.value.$touch();
  if (!$v.value.$invalid) {
    setLoading(true);
    await store.dispatch(Actions.StoreShortcut, shortcut);

    events.emit(Event.ShortcutCreated, null);

    store.dispatch(DrawerActions.CloseDrawer);

    setLoading(false);
    cancel();
  }
};

const cancel = () => {
  if (props.data) {
    emit("close");
  } else {
    router.push(`/shortcut/${shortcut.id}`);
  }
};

const choose = (choice: Shortcut) => {
  if (choice) {
    store.commit(Mutations.SetShortcut, choice);
    events.emit(Event.ShortcutCreated, null);
    store.dispatch(DrawerActions.CloseDrawer);
    cancel();
  }
};
</script>
<template>
  <section class="drawer-content shortcut-form">
    <ShortcutAddForm
      @choose="choose"
      :shortcuts="props.data?.shortcuts"
    ></ShortcutAddForm>
    <form
      @submit.prevent="submit"
      :class="{ submitting: loading }"
      v-if="shortcut"
    >
      <h2 v-text="isNew ? 'Add shortcut' : 'Edit shortcut'"></h2>
      <p v-if="isNew">Fill out the details of the shortcut</p>
      <p v-else>Edit the details of the shortcut</p>

      <Field
        label="Name"
        :validation="$v.shortcut.name"
        v-model="shortcut.name"
      ></Field>
      <FieldChoice
        label="Type"
        v-model="shortcut.type"
        :items="typeOptions"
      ></FieldChoice>
      <FieldLong
        label="Description"
        :validation="$v.shortcut.description"
        v-model="shortcut.description"
        styling="field-text"
      ></FieldLong>
      <FieldChoice
        label="Application"
        v-model="shortcut.application_id"
        :items="applicationOptions"
      ></FieldChoice>
      <FieldChoice
        label="Mode"
        v-model="shortcut.mode"
        :items="modeOptions"
      ></FieldChoice>
      <Field
        label="Shortcut Key"
        :validation="$v.shortcut.shortcut_key"
        v-model="shortcut.shortcut_key"
      ></Field>
      <div class="fields">
        <button type="button" @click="shortcut.ctrl_key = !shortcut.ctrl_key">
          <span v-if="shortcut.ctrl_key" class="icon icon-ok"></span>Ctrl
        </button>
        <button type="button" @click="shortcut.alt_key = !shortcut.alt_key">
          <span v-if="shortcut.alt_key" class="icon icon-ok"></span>Alt
        </button>
        <button type="button" @click="shortcut.shift_key = !shortcut.shift_key">
          <span v-if="shortcut.shift_key" class="icon icon-ok"></span>Shift
        </button>
        <button
          type="button"
          @click="shortcut.command_key = !shortcut.command_key"
        >
          <span v-if="shortcut.command_key" class="icon icon-ok"></span>Command
        </button>
      </div>
      <div class="fields">
        <button
          type="button"
          @click="shortcut.left_mouse_button = !shortcut.left_mouse_button"
        >
          <span v-if="shortcut.left_mouse_button" class="icon icon-ok"></span
          >LMB
        </button>
        <button
          type="button"
          @click="shortcut.right_mouse_button = !shortcut.right_mouse_button"
        >
          <span v-if="shortcut.right_mouse_button" class="icon icon-ok"></span
          >RMB
        </button>
      </div>
      <div class="buttons">
        <button
          class="confirm"
          type="button"
          :disabled="loading"
          @click="submit"
        >
          <span class="icon icon-pencil"></span>
          <span class="text" v-text="isNew ? 'Add' : 'Edit'"></span>
        </button>
        <button class="cancel" type="button" @click="cancel">
          <span class="text">Cancel</span>
        </button>
        <Loading v-if="loading"></Loading>
      </div>
      <div v-if="relatedShortcuts.length">
        <h3>Did you mean?</h3>
        <ul>
          <li v-for="related in relatedShortcuts">
            <ShortcutKey :shortcut="related"></ShortcutKey>
          </li>
        </ul>
      </div>
    </form>
  </section>
</template>
