<script setup lang="ts">
import { computed, markRaw } from "vue";
import { useStore } from "vuex";
import { Shortcut } from "@/models/shortcut";
import { Actions } from "@/store";
import ShortcutKey from "./ShortcutKey.vue";
import { DrawerMutations } from "@/stores/drawer.store";
import ShortcutForm from "../shortcut/ShortcutForm.vue";
import Filter from "../common/Filter.vue";

const store = useStore();
const shortcuts = computed<Shortcut[]>(() =>
  [...store.getters.filteredShortcuts].sort((a, b) =>
    a.name > b.name ? 1 : -1
  )
);

const addShortcut = () => {
  store.dispatch(Actions.NewShortcut);
  store.commit(DrawerMutations.OpenDrawer, {
    drawer: markRaw(ShortcutForm),
    data: {
      drawer: true
    }
  });
};
</script>
<template>
  <div class="buttons">
    <Filter />
    <button type="button" class="option" @click="addShortcut">
      <span class="icon icon-pencil"></span> Add Shortcut
    </button>
  </div>
  <section class="shortcut">
    <h2>Shortcuts</h2>
    <ul class="col half shortcut-list">
      <li v-for="shortcut in shortcuts">
        <ShortcutKey :shortcut="shortcut"></ShortcutKey>
      </li>
    </ul>
  </section>
</template>
