<script lang="ts" setup>
import { Address } from '@/models/address';
import Field from '@/components/common/Field.vue';
import { PropType } from 'vue';

const { modelValue, validation, loading } = defineProps({
  modelValue: { type: Object as PropType<Address>, required: true },
  validation: { type: Object, required: true },
  loading: { type: Boolean, required: true },
});

const emit = defineEmits(['update:modelValue']);

const update = () => {
  emit('update:modelValue', modelValue);
};
</script>
<template>
  <div v-if="validation">
    <p>Address information</p>
    <Field
      label="Name"
      prop="address"
      @input="update"
      :validation="validation.name"
      v-model="modelValue.name"
      :disabled="loading"
    ></Field>
    <Field
      label="Line 1"
      prop="address"
      @input="update"
      :validation="validation.line_one"
      v-model="modelValue.line_one"
      :disabled="loading"
    ></Field>
    <Field
      label="Line 2"
      prop="address"
      @input="update"
      :validation="validation.line_two"
      v-model="modelValue.line_two"
      :disabled="loading"
    ></Field>
    <div class="fields">
      <label></label>
      <Field
        label="Zipcode"
        prop="address"
        @input="update"
        :validation="validation.zipcode"
        v-model="modelValue.zipcode"
        :disabled="loading"
        styling="block"
      ></Field>
      <Field
        label="City"
        prop="address"
        @input="update"
        :validation="validation.city"
        v-model="modelValue.city"
        :disabled="loading"
        styling="block"
        message-styling="bottom"
      ></Field>
    </div>
  </div>
</template>
