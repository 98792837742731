<script lang="ts" setup>
import { PropType } from 'vue';

export interface CompoundListItem {
  label?: string;
  target?: string;
  targetDescription?: string;
  name: string;
  description?: string;
  styling?: string | null;
}

const { list, simple } = defineProps({
  list: { type: Array as PropType<CompoundListItem[]>, required: true },
  simple: { type: Boolean, default: false },
});
</script>
<template>
  <ul class="compound" :class="{ 'compound-simple': simple }">
    <li v-for="(item, index) in list" :key="index" :class="item.styling">
      <span class="primary-label" v-if="item.label" v-text="item.label"></span>
      <section>
        <RouterLink
          v-if="item.target"
          :to="item.target"
          v-text="item.name"
          class="name"
        ></RouterLink>
        <span v-else class="name" v-text="item.name"></span>
        <p class="description" v-if="item.description">
          <RouterLink
            v-if="item.targetDescription"
            :to="item.targetDescription"
            v-text="item.description"
          ></RouterLink>
          <span v-else v-text="item.description" />
        </p>
      </section>
    </li>
  </ul>
</template>
