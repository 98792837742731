<script lang="ts" setup>
import { PropType, computed, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { Actions, Mutations } from "@/store";
import { Note, noteValidations } from "@/models/note";
import { useLoading } from "../common/loading.composable";
import FieldChoice, { Item } from "../common/FieldChoice.vue";
import { DrawerActions } from "@/stores/drawer.store";
import { useEvents, Event } from "@/core/services/events.composable";

const store = useStore();
const events = useEvents();

const props = defineProps({
  data: {
    type: Object as PropType<{ application: number; notes: Note[] }>
  }
});

const noteOptions = computed<Item[]>(() =>
  (props.data?.notes || []).map((s) => {
    return {
      label: s.title,
      value: s
    };
  })
);

const choice = reactive({ value: null });

const note = reactive<Note>({
  ...store.state.note
});

const { loading, setLoading } = useLoading();

onMounted(() => {
  store.dispatch(Actions.GetApplications);

  store.dispatch(Actions.NewNote);
  const application_id =
    props.data?.application || store.state.note.application_id;
  Object.assign(note, { ...store.state.note, application_id });
});

const $v = useVuelidate(noteValidations, { note });
const emit = defineEmits(["close"]);

const cancel = () => {
  emit("close");
};

const choose = () => {
  if (choice.value) {
    store.commit(Mutations.SetNote, choice.value);
    events.emit(Event.NoteCreated, choice.value);
    store.dispatch(DrawerActions.CloseDrawer);
    cancel();
  }
};
</script>
<template>
  <section class="drawer-content note-form">
    <form v-if="props.data?.notes?.length">
      <h2>Choose note</h2>
      <FieldChoice
        label="Note"
        v-model="choice.value"
        :items="noteOptions"
      ></FieldChoice>
      <div class="buttons">
        <button
          class="confirm"
          type="button"
          :disabled="loading"
          @click="choose"
        >
          <span class="icon icon-pencil"></span>
          <span class="text">Add</span>
        </button>
      </div>
    </form>
  </section>
</template>
@/models/note
