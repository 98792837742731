<script setup lang="ts">
import { formatMonth } from "@/filters/filters";
import { CalendarData, CalendarDay } from "@/models/calendar";
import { Learning } from "@/models/learning";
import { isSameDay } from "date-fns";
import { PropType } from "vue";

const props = defineProps({
  learnings: {
    type: Array as PropType<Learning[]>,
    required: true
  },
  calendar: {
    type: Object as PropType<CalendarData>,
    required: true
  }
});

const emit = defineEmits(["edit"]);

const hasLearning = (day: CalendarDay): boolean => {
  return props.learnings.some((l) =>
    isSameDay(new Date(l.date), new Date(day.internal))
  );
};

const edit = (day: CalendarDay) => {
  emit("edit", {
    ...day,
    learnings: props.learnings.filter((l) =>
      isSameDay(new Date(l.date), new Date(day.internal))
    )
  });
};
</script>
<template>
  <h2 v-text="formatMonth(calendar.start)"></h2>
  <div>
    <ul class="learning-calendar">
      <li
        v-for="day in calendar.days"
        @click="edit(day)"
        :class="{
          day: true,
          'day-previous': day.is_previous,
          'day-next': day.is_next,
          'day-learned': hasLearning(day)
        }"
      >
        <span class="day-date" v-text="day.day"></span>
        <span class="day-icon" v-if="hasLearning(day)">✓</span>
      </li>
    </ul>
  </div>
</template>
<style scoped>
.learning-calendar {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.day {
  width: calc(100% / 7 - 4px);
  height: 60px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  border: solid 1px rgb(160, 160, 160);
  border-top-color: rgb(200, 200, 200);
  border-left-color: rgb(200, 200, 200);
  margin: 2px;
  border-radius: 2px;
  background-color: rgb(255 248 207);
}

.day:hover {
  cursor: pointer;
  background-color: rgb(226, 220, 183);
}

.day-previous,
.day-next {
  color: rgb(143, 143, 143);
}
.day:nth-child(7) {
  clear: right;
}

.day-date {
  color: rgb(92, 92, 92);
  position: absolute;
  top: 0px;
  right: 6px;
  font-size: 11px;
}
.day-next .day-date {
  color: rgb(204, 204, 204);
}

.day-learned {
  background-color: rgb(149 195 119);
}
.day-icon {
  font-size: 3.5em;
  color: rgb(111, 151, 84);
}
.day-learned:hover {
  background-color: rgb(137, 181, 108);
}
</style>
