<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";
import { Shortcut } from "@/models/shortcut";
import { convertMarkdown } from "@/markdown/util/markdown.util";
import { Actions, Mutations } from "@/store";
import { useRouter } from "vue-router";
import ShortcutKey from "../shortcut/ShortcutKey.vue";

const store = useStore();
const router = useRouter();
const shortcut = computed<Shortcut>(() => store.state.shortcut);
const shortcuts = computed(() => store.state.shortcuts);

const getShortcuts = (shortcut: Shortcut): Shortcut[] =>
  shortcuts.value.filter((s: Shortcut) => shortcut.modifiers.includes(s.id));

const edit = () => {
  store.commit(Mutations.SetShortcut, shortcut.value);
  router.push(`/shortcut/${shortcut.value.id}/edit`);
};

const remove = () => {
  store.dispatch(Actions.RemoveShortcut, shortcut.value.id);
  router.push(`/shortcut`);
};
</script>
<template>
  <div class="buttons">
    <button type="button" class="option" @click="remove">
      <span class="icon icon-remove"></span> Delete
    </button>
    <button type="button" class="option" @click="edit">
      <span class="icon icon-pencil"></span> Edit
    </button>
  </div>
  <section class="shortcut" v-if="shortcut">
    <h2 v-text="shortcut.name"></h2>
    <ShortcutKey :shortcut="shortcut"></ShortcutKey>
    <p v-html="convertMarkdown(shortcut.description)"></p>
    <div v-if="(shortcut.modifiers || []).length">
      <h3>Related shortcut keys</h3>
      <div class="columns">
        <ul class="col half">
          <li v-for="s in getShortcuts(shortcut)">
            <ShortcutKey :shortcut="s"></ShortcutKey>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>
