<script setup lang="ts">
import { PropType, onMounted, reactive, ref } from "vue";
import {
  getAttributeConfig,
  getElementName,
  getLabel,
  getPlaceholder
} from "../config";
import { Attribute } from "../types";

const props = defineProps({
  currentElement: {
    type: Object as PropType<HTMLElement>,
    required: true
  },
  show: {
    type: Boolean,
    required: true
  },
  uploadPath: {
    type: String,
    required: true
  }
});

const attributes = reactive<Attribute[]>([]);

onMounted(() => {
  for (var attr of props.currentElement.attributes) {
    const name = attr.name;
    attributes.push({ name, value: attr.value });
  }
});

const emit = defineEmits(["confirm", "cancel"]);

const confirm = () => {
  emit("confirm", attributes);
};

const cancel = () => {
  emit("cancel");
};

const filename = ref<HTMLInputElement[]>();
const upload = ref<HTMLInputElement[]>();
const attribute = ref();
const hasUpload = ref<boolean>(false);
const updateFile = (attr: Attribute, e: Event) => {
  hasUpload.value = true;

  attribute.value = attr;
};

const uploadFile = async () => {
  hasUpload.value = true;

  const input = upload.value;
  const files = input ? input[0].files : [];
  const file = files && files.length ? files[0] : null;

  if (!file) {
    throw new Error("File not selected");
  }

  const body = new FormData();

  body.set("filename", file.name);
  body.append("file", file);

  const result = await fetch(props.uploadPath, {
    method: "POST",
    body
  });

  const uploaded = (await result.text()).replace(/"/g, "");

  const index = attributes.findIndex((a) => a.name === attribute.value.name);

  if (index !== -1) {
    attributes[index].value = `/api/file/${uploaded}`.trim();
  }
};
</script>
<template>
  <div class="editor-attribute-dialog" v-if="props.show">
    <form @submit.prevent="confirm" class="attribute-form">
      <h4>
        <span
          v-text="getElementName(props.currentElement.tagName.toLowerCase())"
        ></span>
        options
      </h4>
      <div class="field" v-for="attribute in attributes">
        <label v-text="getLabel(attribute.name)"></label>

        <div
          class="input"
          v-if="getAttributeConfig(attribute.name).hasImageLookup"
        >
          <input type="text" v-model="attribute.value" ref="filename" />
          <input
            type="file"
            ref="upload"
            @change="(e) => updateFile(attribute, e)"
            :placeholder="getPlaceholder(attribute.name)"
          />
          <button type="button" :disabled="!hasUpload" @click="uploadFile">
            Upload
          </button>
        </div>
        <input
          v-else-if="getAttributeConfig(attribute.name).hasLinkLookup"
          type="text"
          v-model="attribute.value"
          :placeholder="getPlaceholder(attribute.name)"
        />
        <select
          v-else-if="getAttributeConfig(attribute.name).options"
          v-model="attribute.value"
        >
          <option value>None</option>
          <option
            :value.attr="opt.value"
            v-for="opt in getAttributeConfig(attribute.name).options"
            v-text="opt.label"
          ></option>
        </select>
        <input
          v-else
          type="text"
          v-model="attribute.value"
          :placeholder="getPlaceholder(attribute.name)"
        />
      </div>
      <div class="buttons">
        <button type="button" @click="cancel">Cancel</button>
        <button type="submit">Confirm</button>
      </div>
    </form>
  </div>
</template>
<style scoped src="../css/markdown-editor.less" lang="less"></style>
