<script setup lang="ts">
import { PropType, computed } from "vue";
import { useRouter } from "vue-router";

import { Blog } from "@/models/blog";
import { formatDateLong } from "@/filters/filters";
import { convertMarkdown } from "@/markdown/util/markdown.util";
import { useStore } from "vuex";
import { Mutations } from "@/store";

const store = useStore();
const props = defineProps({
  blog: {
    type: Object as PropType<Blog>,
    required: true
  },
  listed: {
    type: Boolean,
    default: false
  }
});

const auth = computed(() => store.state.auth);

const router = useRouter();

const navigate = (id: number) => {
  router.push(`/blog/${id}`);
};
const edit = () => {
  store.commit(Mutations.SetBlog, props.blog);
  router.push(`/blog/${props.blog.id}/edit`);
};
</script>
<template>
  <section class="blog" v-if="props.blog">
    <div class="buttons" v-if="!listed && auth">
      <button type="button" class="option" @click="edit">
        <span class="icon icon-pencil"></span> Edit
      </button>
    </div>
    <h2 v-text="props.blog.title" @click="navigate(props.blog.id)"></h2>
    <i class="date">{{ formatDateLong(props.blog.date) }}</i>
    <div v-html="convertMarkdown(props.blog.text)"></div>
  </section>
</template>
